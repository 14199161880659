import React from 'react';
import { Table, Button, Empty, Spin } from 'antd';

interface GoFormzData {
  projectName: string; // Assuming it's a string for proper filtering
  formzUserName: string;
  formName: string;
  creationDateTime: string;
  formzPdfUrl: string;
}

interface GoFormzComponentProps {
  data: GoFormzData[];
  isLoading: boolean;
}

const GoFormzComponent: React.FC<GoFormzComponentProps> = ({ data, isLoading }) => {
  // Extract unique project names for filtering
  const projectFilters = Array.from(
    new Set(data.map((item) => item.projectName))
  ).map((projectName) => ({ text: projectName, value: projectName }));

  const columns = [
    {
      title: <b>Project</b>,
      dataIndex: 'projectName',
      key: 'projectName',
      filters: projectFilters,
      onFilter: (value: string | number | boolean, record: GoFormzData) =>
        record.projectName === value,
      render: (projectName: string) => <span>{projectName}</span>,
    },
    {
      title: <b>User Name</b>,
      dataIndex: 'formzUserName',
      key: 'formzUserName',
      render: (userName: string) => <span>{userName}</span>,
    },
    {
      title: <b>Form Name</b>,
      dataIndex: 'formName',
      key: 'formName',
      render: (formName: string) => <span>{formName}</span>,
    },
    {
      title: <b>Date & Time</b>,
      dataIndex: 'creationDateTime',
      key: 'creationDateTime',
      render: (dateTime: string) => <span>{new Date(dateTime).toLocaleString()}</span>,
    },
    {
      title: <b>Action</b>,
      key: 'action',
      render: (_: any, record: GoFormzData) => (
        <Button
          type="primary"
          onClick={() => window.open(JSON.parse(record.formzPdfUrl)[0].Url, '_blank')}
        >
          View
        </Button>
      ),
    },
  ];

  return (
    <div className="sa__container">
      <div className="sa__box">
        <div className="sa__box-heading">
          <h4 className="sa__box-heading-title">GoFormz Reports List</h4>
        </div>

        <div className="sa__box-content" style={{ position: 'relative', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', borderRadius: '8px', padding: '16px' }}>
          {isLoading && (
            <div
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                backgroundColor: 'rgba(255, 255, 255, 0.7)',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: '8px',
                zIndex: 1,
              }}
            >
              <Spin size="default" />
            </div>
          )}

          <Table
            columns={columns}
            dataSource={data}
            rowKey="creationDateTime"
            bordered
            pagination={{ pageSize: 10 }}
            style={{ opacity: isLoading ? 0.5 : 1, transition: 'opacity 0.3s' }}
          />
        </div>
      </div>
    </div>
  );
};

export default GoFormzComponent;
