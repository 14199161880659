import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Modal, Form, Input, Spin } from 'antd';
import { IAppState } from '@rdx/root.reducer';
import { ErrorMessage } from '@shared/types/utils.types';
import { ScheduleType, UserType } from '@shared/types/backend.types';
import axios from 'axios';
import {
  fetchAccountsScheduleAction,
  removeScheduleAction,
  updateScheduleAction,
} from '@app/back-office/schedule-job/state/schedule.actions';
import { removeBackofficeErrorAction } from '@app/back-office/professional.action';
import {
  getProfessionalIsLoading,
  getErrorMessages,
  getSchedule,
} from '@app/back-office/professional.selector';
import ScheduleTableComponent from '../view/schedule.component';
import { openNotificationWithIcon } from '@components/controls/notification/notification.component';
import { getSessionUser } from '@app/session/session.selector';
import Cookies from 'js-cookie';
import {
  DELETE_SCHEDULED_JOB_API,
  GET_SCHEDULED_JOBS_API,
  UPDATE_SCHEDULED_JOB_API,
  API_URL,
  FETCH_USER_TEAM_API,
} from '@constants/endpoints';
import { ScheduleFetchPayload } from '../schedule';
import useForm from 'antd';
import SASpin from '@components/ui/accordian/spin';
import CenteredLoader from '@components/ui/accordian/spinner';
import { message } from 'antd';
interface IScheduleLockedProps {
  user?: any;
  isLoading: boolean;
  errorMessages: { [key: string]: ErrorMessage | undefined };
  schedule: ScheduleType[];
  fetchAccountsScheduleAction: (payload: ScheduleFetchPayload) => void;
  removeErrorAction: (code: string) => void;
}



const ScheduleLocked: React.FC<IScheduleLockedProps> = ({
  user,
  isLoading,
  errorMessages,
  schedule,
  fetchAccountsScheduleAction,
  removeErrorAction,
}) => {
  const [editingSchedule, setEditingSchedule] = useState<ScheduleType | null>(null);
  const [isEditModalVisible, setEditModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [associates, setAssociates] = useState<any[]>([]);
  const tokenKey = `Bearer ${Cookies.get('sessionKey')}`;
  useEffect(() => {
    if (user) {
      //fetchAssosiates();
      fetchAccountsScheduleAction({ accountID: user.user_metadata.accountID,userID:user.user_id });
    }
  }, [user, fetchAccountsScheduleAction]);

  useEffect(() => {
    if (Object.keys(errorMessages).length > 0) {
      Object.values(errorMessages).forEach(error => {
        if (error) {
          openNotificationWithIcon('error', error.title, error.message, '');
        }
      });
      Object.keys(errorMessages).forEach(code => {
        removeErrorAction(code);
      });
    }
  }, [errorMessages, removeErrorAction]);

  const handleUpdateSchedule = (values: any) => {
    if (user && editingSchedule) {
      dispatch(
        updateScheduleAction.request({
          project: { ...editingSchedule, ...values },
          accountID: user.user_metadata.accountID,
        }),
      );
      setEditingSchedule(null);
      setEditModalVisible(false);
    }
  };

  const handleRefresh = async (): Promise<void> => {
    if (user) {
      fetchAccountsScheduleAction({ accountID: user.user_metadata.accountID,userID:user.user_id });
    }
  };

  const handleSend = async (jobDetails: any) => {
    console.log(jobDetails);
    console.log(user);
    if (user) {
      let type = jobDetails.activityType;
      const Admin_Api_Key = user.user_metadata.acAPIKey;
      let selectProjectId = jobDetails.projectIds || '';
      let safetyPlanName = jobDetails.safetyPrograms || '';
      let selectUser = jobDetails.userIds;
      let range = jobDetails.daysRange;
      const reportUrl = API_URL;
      if (type === 'submissions_report') {
        const url =
          reportUrl +
          '/reports/' +
          Admin_Api_Key +
          '/submissions-export?userId=' +
          user.user_id +
          '&projectIds=' +
          selectProjectId +
          '&safetyPrograms=' +
          safetyPlanName +
          '&userIds=' +
          selectUser +
          '&daysRange=' +
          range;
        window.open(url, '_blank');
      } else if (type === 'ca_report') {
        const url =
          reportUrl +
          '/reports/' +
          Admin_Api_Key +
          '/ca-export?userId=' +
          user.user_id +
          '&projectIds=' +
          selectProjectId +
          '&userIds=' +
          selectUser +
          '&daysRange=' +
          range;
        window.open(url, '_blank');
      } else if (type === 'ptp_submissions_report') {
        const url =
          reportUrl +
          '/reports/' +
          Admin_Api_Key +
          '/ptp-submissions-export?projectIds=' +
          selectProjectId +
          '&userIds=' +
          selectUser +
          '&daysRange=' +
          range;

        window.open(url, '_blank');
      } else if (type === 'cc_report') {
        const url = reportUrl + '/reports/' + Admin_Api_Key + '/cc-export?userID=' + user.user_id;

        window.open(url, '_blank');
      } else if (type === 'projects_report') {
        const url =
          reportUrl + '/reports/' + Admin_Api_Key + '/projects-export?userID=' + user.user_id;

        window.open(url, '_blank');
      } else if (type === 'associates_report') {
        const url =
          reportUrl + '/reports/' + Admin_Api_Key + '/associates-export?userID=' + user.user_id;

        window.open(url, '_blank');
      }
      else if(type === "goformz_report"){
        const url =
        reportUrl +
        '/reports/' +
        Admin_Api_Key +
        '/formzsubmissions-export?projectIds=' +
        selectProjectId +
        '&userIds=' +
        selectUser +
        '&daysRange=' +
        range;

        window.open(url, '_blank');
      }
    }
  };

  const handleRemove = async (scheduleId: string) => {
    message.loading({ content: 'Deleting Schedule', key: 'delSchedule' }, 0);
   
    if (user) {
      const endpoint = DELETE_SCHEDULED_JOB_API.replace(':scheduleJobId', scheduleId).replace(
        ':id',
        `${user.user_metadata.accountID}`,
      );
      try {
        await axios.delete(endpoint, { headers: { Authorization: tokenKey } });
        fetchAccountsScheduleAction({ accountID: user.user_metadata.accountID,userID:user.user_id });
       
      } catch (error) {
        console.error('Error deleting schedule:', error);
      } finally {
        message.success({ content: 'Schedule Report Deleted Successfully', key: 'delSchedule' }, 2);
      }
    }
  };



  const handleEdit = async (scheduleId: string, jobDetails: any) => {
    console.log('jobDetails',jobDetails);
    message.loading({ content: 'Update Schedule', key: 'upSchedule' }, 0);
    //setLoading(true);
    if (user) {
      const endpoint = UPDATE_SCHEDULED_JOB_API.replace(':id', `${user.user_metadata.accountID}`);

      let status = '';

      let frequencyValue = '';
      if (jobDetails.jobFrequency === 'weekly') {
        frequencyValue = jobDetails.dayOfWeek;
      } else {
        frequencyValue = jobDetails.dayOfMonth;
      }
      const requestBody = {
        scheduleJobId: scheduleId,
        jobTitle: jobDetails.jobTitle,
        jobDescription: jobDetails.jobDescription,
        jobFrequency: jobDetails.jobFrequency,
        JobTimezone: jobDetails.timezone_txt,
        JobFrequencyValue: frequencyValue,
        jobTime: jobDetails.jobTime,
        recipientEmails: jobDetails.recipientEmails,
        status: jobDetails.status ? 'active' : 'inactive',
      };

      console.log(requestBody);
      try {
        if (scheduleId) {
          await axios.put(`${endpoint}`, requestBody, {
            headers: { Authorization: tokenKey },
          });
        } else {
          await axios.post(endpoint, requestBody, {
            headers: { Authorization: tokenKey },
          });
        }
      } catch (error) {
        console.error('Error scheduling job:', error);
      } finally {
        message.success({ content: 'Schedule Report Updated Successfully', key: 'upSchedule' }, 2);
        if (user) {
          fetchAccountsScheduleAction({ accountID: user.user_metadata.accountID,userID:user.user_id });
        }
      }
    }
  };

  const handleSwitch = async (jobDetails: any, scheduleId: any, checked: any) => {
    message.loading({ content: 'Update Schedule Status', key: 'stSchedule' }, 0);
    let status = '';
    if (checked === true) {
      status = 'active';
    } else {
      status = 'inactive';
    }
   
    if (user) {
      const endpoint = UPDATE_SCHEDULED_JOB_API.replace(':id', `${user.user_metadata.accountID}`);

      const requestBody = {
        scheduleJobId: scheduleId,
        jobTitle: jobDetails.jobTitle,
        jobDescription: jobDetails.jobDescription,
        jobFrequency: jobDetails.jobFrequency,
        JobTimezone: jobDetails.jobTimezone,
        JobFrequencyValue: jobDetails.jobFrequencyValue,
        jobTime: jobDetails.jobTime,
        recipientEmails: jobDetails.recipientEmails,
        status: status,
      };

      console.log(requestBody);
      try {
        if (scheduleId) {
          await axios.put(`${endpoint}`, requestBody, {
            headers: { Authorization: tokenKey },
          });
        } else {
          await axios.post(endpoint, requestBody, {
            headers: { Authorization: tokenKey },
          });
        }
      } catch (error) {
        console.error('Error scheduling job:', error);
      } finally {
        message.success({ content: 'Schedule Report Status Changed Successfully', key: 'stSchedule' }, 2);
        if (user) {
          fetchAccountsScheduleAction({ accountID: user.user_metadata.accountID,userID:user.user_id });
        }
      }
    }
  };

  const fetchAssosiates = async () => {
    if (!user) return;

    const endpoint = FETCH_USER_TEAM_API.replace(':userID', `${user.user_id}`);

    try {
      const response = await axios.get(endpoint, {
        headers: { Authorization: tokenKey },
      });
      const projects = response.data;
   
      const newEmails = projects.map((item: any) => item.email);
      setAssociates(newEmails);
    } catch (error) {
      console.error('Error fetching projects:', error);
    } finally {
    }
  };

  return (
    <>
      {loading ? (
        <CenteredLoader /> // Show loading spinner
      ) : (
        <ScheduleTableComponent
          schedules={schedule}
          isLoading={isLoading}
          handleEdit={handleEdit}
          handleRemove={handleRemove}
          handleSend={handleSend}
          handleToggleStatus={handleSwitch}
          user={user}
          fetchSchedules={handleRefresh}
          fetchAssociates={associates}
        />
      )}
    </>
  );
};

const mapStateToProps = (state: IAppState) => ({
  user: getSessionUser(state),
  isLoading: getProfessionalIsLoading(state),
  errorMessages: getErrorMessages(state),
  schedule: getSchedule(state),
});

const mapDispatchToProps = {
  fetchAccountsScheduleAction: fetchAccountsScheduleAction.request,
  removeErrorAction: removeBackofficeErrorAction.trigger,
};

export const ScheduleLockedContainer = connect(mapStateToProps, mapDispatchToProps)(ScheduleLocked);
