import { createActionRoutine } from '@shared/helpers/redux-saga-endpoints';

import {
FETCH_FORMZ_SUBMISSION,
} from '@constants/endpoints';

export const FETCH_FORMZ_SUBMISSIONS = 'FETCH_FORMZ_SUBMISSIONS';


export const fetchFormzAction = createActionRoutine(
    FETCH_FORMZ_SUBMISSIONS,
    FETCH_FORMZ_SUBMISSION,
);


