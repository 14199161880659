import React from 'react';
import { PowerBiIcon, ReportsIcon } from '@assets/icons/icons.svg';

export const REDIRECTION_PATHS: any = {
  //TODO: add type
  systemAdmin: '/admin/accounts',
  safetyProfessional: '/back-office/account',
};

export const SYS_ADMIN_MENU_PATH = [
  {
    /*
    localeID property refers to language dictionaries: 
    spanishDictionary and englishDictionary object
    */
    localeID: 'sidemenu.systemAdmin.menuTitle',
    children: [
      {
        localeID: 'sidemenu.systemAdmin.account',
        path: '/admin/accounts',
        icon: 'user',
      },
      {
        localeID: 'sidemenu.systemAdmin.invite',
        icon: 'mail',
        subMenu: true,
        subMenuChildren: [
          {
            localeID: 'sidemenu.systemAdmin.subMenu.inviteTeam',
            path: '/admin/invite-team',
            icon: 'usergroup-add',
          },
          {
            localeID: 'sidemenu.systemAdmin.subMenu.inviteAdministrator',
            path: '/admin/invite',
            icon: 'user-add',
          },
        ],
      },
      {
        localeID: 'sidemenu.systemAdmin.safetyPrograms',
        path: '/admin/safety-programs',
        icon: 'check-square',
      },
      {
        localeID: 'sidemenu.systemAdmin.reports',
        icon: <ReportsIcon style={{ marginRight: 10, fontSize: 22 }} />,
        nativeIcon: false,
        subMenu: true,
        subMenuChildren: [
          {
            localeID: 'sidemenu.systemAdmin.subMenu.submissionsReports',
            path: '/admin/submissions',
            icon: 'container',
          },
          {
            localeID: 'sidemenu.systemAdmin.correctiveActions',
            path: '/admin/corrective-actions',
            icon: 'interaction',
          },
        ],
      },
      {
        localeID: 'sidemenu.safetyProfessional.powerBIReport',
        icon: <PowerBiIcon style={{ marginRight: 10, fontSize: 22 }} />,
        nativeIcon: false,
        subMenu: true,
        subMenuChildren: [
          {
            localeID: 'sidemenu.systemAdmin.subMenu.submissionsReports',
            path: '/admin/reports/submissions',
            icon: 'container',
          },
          {
            localeID: 'sidemenu.systemAdmin.subMenu.correctiveActionsReports',
            path: '/admin/reports/corrective-actions',
            icon: 'interaction',
          },
        ],
      },
      {
        localeID: 'sidemenu.systemAdmin.settings',
        icon: 'setting',
        subMenu: true,
        subMenuChildren: [
          {
            localeID: 'sidemenu.systemAdmin.subMenu.hazards',
            path: '/admin/hazards',
            icon: 'warning',
          },
        ],
      },
    ],
  },
];

export const SA_MENU_PATH = [
  {
    localeID: 'sidemenu.dashboard.sectionTitle',
    children: [
      {
        localeID: 'sidemenu.safetyProfessional.account',
        path: '/back-office/account',
        icon: 'user',
      },
      {
        localeID: 'sidemenu.safetyProfessional.projects',
        path: '/back-office/projects',
        icon: 'profile',
      },
      {
        localeID: 'sidemenu.safetyProfessional.safetyPrograms',
        path: '/back-office/plans',
        icon: 'check-square',
      },
      {
        localeID: 'sidemenu.safetyProfessional.inviteTeam',
        path: '/back-office/invite-team',
        icon: 'usergroup-add',
      },
      {
        localeID: 'sidemenu.safetyProfessional.powerBIReport',
        icon: <PowerBiIcon style={{ marginRight: 10, fontSize: 22 }} />,
        nativeIcon: false,
        subMenu: true,
        subMenuChildren: [
          {
            localeID: 'sidemenu.safetyProfessional.subMenu.submissionsReports',
            path: '/back-office/reports/submissions',
            icon: 'container',
          },
          {
            localeID: 'sidemenu.safetyProfessional.subMenu.correctiveActionsReports',
            path: '/back-office/reports/corrective-actions',
            icon: 'interaction',
          },
        ],
      },
      {
        localeID: 'sidemenu.safetyProfessional.reports',
        icon: <ReportsIcon style={{ marginRight: 10, fontSize: 22 }} />,
        nativeIcon: false,
        subMenu: true,
        subMenuChildren: [
          {
            localeID: 'sidemenu.safetyProfessional.submissions',
            path: '/back-office/submissions',
            icon: 'container',
          },
          {
            localeID: 'sidemenu.safetyProfessional.correctiveActions',
            path: '/back-office/corrective-actions',
            icon: 'interaction',
          },
          {
            localeID: 'sidemenu.safetyProfessional.preTaskPlans',
            path: '/back-office/pre-task-plans',
            icon: 'sliders',
          },
          {
            localeID: 'sidemenu.safetyProfessional.goformz',
            path: '/back-office/goformz',
            icon: 'container',
          }
          
        ],
        
      },
      {
        localeID: 'sidemenu.safetyProfessional.scheduleJobs',
        path: '/back-office/schedule-jobs',
        icon: 'check-square',
      },
    ],
  },
];
