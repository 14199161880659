import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './ContactUsButton.css';
import { optionButtons, reportOptions } from '@shared/constants/assitant';
import {
  FETCH_PROJECT_FORMS_API_ALL,
  FETCH_ACCOUNTS_PROJECTS_API,
  FETCH_ACCOUNT_CA_API,
  FETCH_FORM_PDF_API,
  FETCH_PROJECT_FORMS_API,
  PTP_SUBMISSIONS_API,
  API_URL,
  POST_SCHEDULE_JOBS_API,
  FETCH_ACCOUNT_SUBMISSIONS,
  FETCH_ACCOUNTS_CONTRACTORS_API,
  FETCH_USER_TEAM_API,
  FETCH_FORMZ_SUBMISSION,
} from '@constants/endpoints';
import Cookies from 'js-cookie';
import LoadingIndicator from './LoadingIndicator';
import { Console } from 'console';
import telegramIcon from './telegram.png';
import downarrow from './down.png';
import TimeDropdown from './TimeDropdown';
import { Button, message } from 'antd';
import EmailModal from './EmailModal';
import { set } from 'lodash';

interface AddScheduleModalProps {
  openModal: boolean;
  onClose: () => void;
  // onCreate: (newJobDetails: any) => Promise<void>;
  user: any;
  emailLists: any;
  onSubmit: () => void;
}
const ContactUsButton: React.FC<AddScheduleModalProps> = ({
  openModal,
  onClose,
  user,
  emailLists,
  onSubmit,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [selectedOption, setSelectedOption] = useState<string>('Submissions');
  const [selectedBodyTxt, setBodyTxt] = useState<string>('Select Scheduled Report Type');
  const [isModalVisible, setModalVisible] = useState(false);
  const [viewState, setViewState] = useState<
    | 'initial'
    | 'reports'
    | 'projects'
    | 'safetyPlanList'
    | 'schedule'
    | 'nodata'
    | 'submissions'
    | 'success'
    | 'forms'
    | 'projectApi'
    | 'userScreen'
    | 'timeRange'
    | 'assignedUserList'
  >('initial');

  const [errors, setErrors] = useState({
    title: '',
    frequency: '',
    executionTime: '',
    description: '',
    recipientEmails: '',
    timezone: '',
    dayOfMonth: '',
    dayOfWeek: '',
  });

  const validateForm = () => {
    const emailPattern = /^([^\s@]+@[^\s@]+\.[^\s@]+)(,\s*[^\s@]+@[^\s@]+\.[^\s@]+)*$/;
    const isWeekly = jobDetails.frequency === 'weekly';
    const isMonthly = jobDetails.frequency === 'monthly';

    const newErrors = {
      title: jobDetails.title ? '' : ' Schedule Job title is required.',
      frequency: jobDetails.frequency ? '' : ' Frequency is required.',
      executionTime: jobDetails.executionTime ? '' : ' Execution time is required.',
      description: jobDetails.description ? '' : ' Description is required.',
      recipientEmails: emailPattern.test(jobDetails.recipientEmails)
        ? ''
        : ' Valid email(s) are required.',
      timezone: jobDetails.timezone_txt ? '' : 'Timezone is required',
      dayOfMonth:
        isMonthly && !jobDetails.dayOfMonth
          ? 'Day of the month is required for monthly frequency.'
          : '',
      dayOfWeek:
        isWeekly && !jobDetails.dayOfWeek
          ? 'Day of the week is required for weekly frequency.'
          : '',
    };

    setErrors(newErrors);
    return Object.values(newErrors).every(error => error === '');
  };

  const [isShow, setIsShow] = useState<boolean>(false);
  const [isVisible, setIsVisible] = useState<boolean>(true);
  const [projectList, setProjectList] = useState<any[]>([]);
  const [projectAcList, setProjectAcList] = useState<any[]>([]);
  const [caprojectList, setCAProjectList] = useState<any[]>([]);
  const [submissionList, setSubmissionList] = useState<any[]>([]);
  const [allsubmissionList, setAllSubmissionList] = useState<any[]>([]);
  const [formList, setFormList] = useState<any[]>([]); // Added form list state
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const tokenKey: string = `Bearer ${Cookies.get('sessionKey')}`;
  const [filteredList, setfilteredList] = useState<any[]>([]);
  const [showFrequencyOptions, setShowFrequencyOptions] = useState<boolean>(false);
  const [frequency, setFrequency] = useState<string | null>(null);
  const [submissionCount, setSubmissionCount] = useState<number | null>(null);
  const [terms, setTerms] = useState<string | null>(null);
  const [safetyPlanList, setSafetyPlanList] = useState<string[]>([]);
  const [selectedSafetyPlan, setSelectedSafetyPlan] = useState<string>('');
  const [selectedTime, setSelectedTime] = useState<string>('');
  const [selectedRange, setSelectedRange] = useState<any>('All');
  const [selectedState, setState] = useState<string>('');
  const [activityType, setactivityType] = useState<string>('submissions_report');
  const [selectProjectId, setProjectId] = useState<string>('');
  const [selectUser, setSelectedUserId] = useState<string>('');
  const [selectProgram, setProgramname] = useState<any>('');
  const [selectFlag, setFlag] = useState<number>(0);
  const [selectFormLabel, setselectFormLabel] = useState<string>('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedItems, setSelectedItems] = useState<any[]>([]);
  const [selectedNames, setselectedNames] = useState<any[]>([]);
  const [selectedUsers, setselectedAsUsers] = useState<any[]>([]);
  const [selectedCorrective, setselectedCorrective] = useState<any[]>([]);
  const [AssignUser, setAssignUser] = useState<any[]>([]);
  const daysOfWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
  const daysOfMonth = Array.from({ length: 31 }, (_, i) => String(i + 1).padStart(2, '0'));
  const [activeTab, setActiveTab] = useState(reportOptions[0].label || '');
  const [successMessage, setSuccessMessage] = useState('');
  const [collapsed, setCollapsed] = useState<{ [key: string]: boolean }>({});

  const [parentCollapsed, setParentCollapsed] = useState<{ [key: string]: boolean }>({});

  const timezones = [
    { value: 'America/Chicago', label: 'Central Time (Texas)' },
    { value: 'America/New_York', label: 'Eastern Time (US)' },
    { value: 'America/Los_Angeles', label: 'Pacific Time (US)' },
    { value: 'America/Denver', label: 'Mountain Time (US)' },
    { value: 'America/Phoenix', label: 'Arizona (no DST)' },
    { value: 'America/Anchorage', label: 'Alaska Time' },
    { value: 'Pacific/Honolulu', label: 'Hawaii-Aleutian Time' },
    { value: 'America/Toronto', label: 'Eastern Time (Canada)' },
    { value: 'America/Vancouver', label: 'Pacific Time (Canada)' },
    { value: 'America/Edmonton', label: 'Mountain Time (Canada)' },
    { value: 'America/Winnipeg', label: 'Central Time (Canada)' },
    { value: 'America/St_Johns', label: 'Newfoundland Time (Canada)' },
    { value: 'America/Halifax', label: 'Atlantic Time (Canada)' },
    { value: 'America/Regina', label: 'Central Time (Canada - Saskatchewan)' },
    { value: 'America/Iqaluit', label: 'Eastern Time (Canada - Nunavut)' },
    { value: 'America/Whitehorse', label: 'Pacific Time (Canada - Yukon)' },
    { value: 'America/Yellowknife', label: 'Mountain Time (Canada - Northwest Territories)' },
  ];

  const users = [
    { value: 'All', label: 'All' },
    { value: user.user_id, label: user.name },
  ];

  const [jobDetails, setJobDetails] = useState({
    title: '',
    frequency: '',
    executionTime: '',
    description: '',
    recipientEmails: '',
    timezone_txt: 'America/Chicago',
    dayOfMonth: '',
    dayOfWeek: '',
  });

  const [emailList, setEmailList] = useState<string[]>(emailLists);

  const toggleMessageReset = () => {
    setselectFormLabel('');
    setIsVisible(true);
    resetState();
  };

  const toggleMessageBoxReset = () => {
    //setIsVisible(true);
    openModal = false;
    setIsOpen(false);
    resetState();
  };
  const renderedFormNames = new Set<string>();
  const toggleMessageTxt = () => {
    setIsVisible(true);
    setIsVisible(false);
  };

  console.log('selectFlag', selectFlag);

  useEffect(() => {
    //fetchAssosiates();
    setSelectedOption('Submissions Report');
    setactivityType('submissions_report');

    handleOptionClick('Submissions Report');
  }, [selectFlag]);

  const resetState = () => {
    setIsModalOpen(false);
    setJobDetails({
      title: '',
      frequency: '',
      executionTime: '',
      description: '',
      recipientEmails: '',
      timezone_txt: 'America/Chicago',
      dayOfMonth: '',
      dayOfWeek: '',
    });

    setErrors({
      title: '',
      frequency: '',
      executionTime: '',
      description: '',
      recipientEmails: '',
      timezone: '',
      dayOfMonth: '',
      dayOfWeek: '',
    });
  };

  const Admin_Api_Key = user.user_metadata.acAPIKey;

  const handleOptionClick = async (optionLabel: string) => {
    if (optionLabel === 'Submissions Report') {
      setSelectedItems([]);
      setselectedNames([]);
      setselectedAsUsers([]);
      setIsShow(false);
      setSubmissionCount(0);
      setActiveTab(optionLabel);
      setactivityType('submissions_report');
      setSelectedOption('Submissions Report');
      await fetchSubmissions();
    } else if (optionLabel === 'Corrective Actions Report') {
      setSubmissionCount(0);
      setSelectedItems([]);
      setselectedNames([]);
      setselectedAsUsers([]);
      setIsShow(false);
      setActiveTab(optionLabel);
      setSelectedOption('Corrective Actions Report');
      setactivityType('ca_report');

      //await fetchProjects();
      await fetchCAList();
    } else if (optionLabel === 'Pre Task Plans Report') {
      setSubmissionCount(0);
      setSelectedItems([]);
      setselectedNames([]);
      setselectedAsUsers([]);
      setIsShow(false);
      setActiveTab(optionLabel);
      setactivityType('ptp_submissions_report');
      setSelectedOption('Pre Task Plans Report');
      //await fetchProjects();
      await getPtpList([0]);
    }
    else if(optionLabel === 'Goformz Report'){
      setSelectedItems([]);
      setselectedNames([]);
      setselectedAsUsers([]);
      setIsShow(false);
      setActiveTab(optionLabel);
      setactivityType('goformz_report');
      setSelectedOption('Goformz Report');
      await fetchAccountProject();
      

    }
  };

  const fetchSubmissions = async () => {
    setProjectList([]);
    setCAProjectList([]);
    setSubmissionList([]);
    setAllSubmissionList([]);
    setSelectedItems([]);
    setselectedNames([]);
    setselectedAsUsers([]);
    if (!user) return;

    const endpoint = FETCH_PROJECT_FORMS_API_ALL.replace(
      ':accountID',
      `${user.user_metadata.accountID}`,
    );

    setIsLoading(true);
    try {
      const response = await axios.get(endpoint, {
        headers: { Authorization: tokenKey },
      });

      console.log('submissions report', response.data);
      const projectMap = new Map();

      response.data.forEach((item: any) => {
        const { projectID, name } = item.project;
        if (!projectMap.has(projectID)) {
          projectMap.set(projectID, { projectID, name });
        }
      });
      setAllSubmissionList(response.data);
      setfilteredList(response.data);
      setSubmissionList(response.data);
      setSubmissionCount(response.data.length);
      //setIsShow(true);
      const projectList = Array.from(projectMap.values());
      const projects = projectList;
      const initialCollapsedState: { [key: string]: boolean } = {};
      response.data.forEach((item: any) => {
        initialCollapsedState[item.safetyPlanName] = true;
      });
      setCollapsed(initialCollapsedState);
      setParentCollapsed(initialCollapsedState);
      //setCollapsed(initialCollapsedState);
      const projectsWithAll = [{ projectID: 0, name: 'All' }, ...projects];
      setProjectList(projectsWithAll);

      const safetyPlanNames = Array.from(
        new Set(response.data.map((item: any) => item.safetyPlanName)),
      );

      const safetyPlansWithAll: any[] =
        response.data || safetyPlanNames.length > 0 ? ['All', ...safetyPlanNames] : safetyPlanNames;

      setBodyTxt('Please Select Program');

      setSafetyPlanList(response.data);
      setTimeout(() => {
        setIsShow(true);
        setBodyTxt('Please Select Project');
      }, 800);

      if (safetyPlanNames.length === 0) {
        setFormList(safetyPlanNames);
        setBodyTxt('');
      } else {
        setFormList(safetyPlanNames);
        setViewState('safetyPlanList');
      }
    } catch (error) {
      console.error('Error fetching projects:', error);
      setSelectedOption('Failed to load projects. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const fetchFormsForProject = async (projectID: any, selectedNames: any, user_ids: any) => {
    if (!user) return;
    console.log('selectedNames:------->>>', selectedNames);
    const projectIDArray: any[] = projectID || [];
    const nameIDArray: any[] = selectedNames || [];
    const userIDArray: any[] = user_ids || [];

    

    let filteredData = [];

    const showAllSubmissions = projectIDArray.includes(0) || projectIDArray.length === 0;
    const includeAllUsers = userIDArray.includes('All') || userIDArray.length === 0;
    const includeAllPrograms = nameIDArray.includes('All') || nameIDArray.length === 0;

    try {
      console.log('projectIDArray:', projectIDArray);
      console.log('nameIDArray:', nameIDArray);
      console.log('userIDArray:', userIDArray);

      if (showAllSubmissions && includeAllUsers && includeAllPrograms) {
        filteredData = allsubmissionList;
      } else if (showAllSubmissions) {
        filteredData = allsubmissionList.filter(
          item =>
            (includeAllUsers || userIDArray.includes(item.project.assignedToUserID)) &&
            (includeAllPrograms || nameIDArray.includes(item.safetyPlanName)),
        );
      } else if (includeAllUsers) {
        filteredData = allsubmissionList.filter(
          item =>
            (showAllSubmissions || projectIDArray.includes(item.project.projectID)) &&
            (includeAllPrograms || nameIDArray.includes(item.safetyPlanName)),
        );
      } else if (includeAllPrograms) {
        filteredData = allsubmissionList.filter(
          item =>
            (showAllSubmissions || projectIDArray.includes(item.project.projectID)) &&
            (includeAllUsers || userIDArray.includes(item.project.assignedToUserID)),
        );
      } else {
        filteredData = submissionList.filter(
          item =>
            projectIDArray.includes(item.project.projectID) &&
            nameIDArray.includes(item.safetyPlanName) &&
            userIDArray.includes(item.project.assignedToUserID),
        );
      }

      console.log('Filtered Data:', filteredData);

      if (filteredData.length === 0) {
        console.warn('No submissions match the selected filters.');
      }

      setSubmissionCount(filteredData.length);
      setfilteredList(filteredData);
    } catch (error) {
      console.error('Error filtering forms:', error);
    }
  };

  const fetchAccountProject = async () => {
    setIsLoading(true);
    if (!user) return;

    const endpoint = FETCH_ACCOUNTS_PROJECTS_API.replace(':accountID', `${user.user_metadata.accountID}`);

    try {
      const response = await axios.get(endpoint, {
        headers: { Authorization: tokenKey },
      });

      console.log('Fetched Data Array:55', response.data);
      setProjectAcList(response.data);
      await fetchGoformz(response.data);
    } catch (error) {
      setSelectedOption('No Submissions:');
      console.error('Error fetching forms:', error);
    } finally {
      //setIsLoading(false);
    }
  };

  const fetchGoformz = async (projectAcList:any) => {
    //setIsLoading(true);
    setSubmissionCount(0);
    setselectedAsUsers([]);
    setSubmissionList([]);
    setAllSubmissionList([]);
    setCAProjectList([]);
    setSelectedItems([]);
    if (!user) return;

    const endpoint = FETCH_FORMZ_SUBMISSION.replace(':accountID', `${user.user_metadata.accountID}`);

    try {
        const response = await axios.get(endpoint, {
            headers: { Authorization: tokenKey },
        });

        console.log('Fetched Data Array:', response.data);
        console.log('Fetched Data projectAcList:', projectAcList);

        // Step 1: Create a map for projectAcList by projectID for quick lookup
        const projectMap = new Map(projectAcList.map((project:any) => [project.projectID, project.name]));

        // Step 2: Add project names to submissions using projectMap
        const enrichedSubmissions = response.data.map((submission:any) => ({
            ...submission,
            projectName: projectMap.get(submission.projectId) || 'Unknown',
        }));

        // Step 3: Create a unique project array for dropdown
        const projectArray = [
            { projectID: 0, name: 'All' },
            ...Array.from(
                new Map(
                    enrichedSubmissions.map((item:any) => [
                        item.projectId,
                        { projectID: item.projectId, name: projectMap.get(item.projectId) || 'Unknown' },
                    ])
                ).values()
            ),
        ];

        setTimeout(() => {
            setIsShow(true);
            setBodyTxt('Please Select Project');
        }, 800);

        setSubmissionCount(enrichedSubmissions.length);
        setCAProjectList(projectArray);
        setfilteredList(enrichedSubmissions);
        setSubmissionList(enrichedSubmissions);
        setAllSubmissionList(enrichedSubmissions);
        setselectedCorrective(enrichedSubmissions);

        setBodyTxt('Select Assigned User');
        setViewState('assignedUserList');
    } catch (error) {
        setSelectedOption('No Submissions:');
        console.error('Error fetching forms:', error);
    } finally {
        setIsLoading(false);
    }
};


  const fetchCAList = async () => {
    setIsLoading(true);
    setSubmissionCount(0);
    setselectedAsUsers([]);
    setSubmissionList([]);
    setAllSubmissionList([]);
    setCAProjectList([]);
    setSelectedItems([]);
    if (!user) return;

    const endpoint = FETCH_ACCOUNT_CA_API.replace(':accountID', `${user.user_metadata.accountID}`);

    try {
      const response = await axios.get(endpoint, {
        headers: { Authorization: tokenKey },
      });

      const assignedToUserArray = [
        { label: 'All', value: 'All' },
        ...Array.from(
          new Map(
            response.data.map((item: any) => [
              item.assignedTo,
              { label: item.assignedToUser, value: item.assignedTo },
            ]),
          ).values(),
        ),
      ];

      const projectArray = [
        { projectID: 0, name: 'All' },
        ...Array.from(
          new Map(
            response.data.map((item: any) => [
              item.projectID,
              { projectID: item.projectID, name: item.projectName },
            ]),
          ).values(),
        ),
      ];
      setTimeout(() => {
        setIsShow(true);
        setBodyTxt('Please Select Project');
      }, 800);
      setSubmissionCount(response.data.length);
      setCAProjectList(projectArray);
      setfilteredList(response.data);
      setSubmissionList(response.data);
      setAllSubmissionList(response.data);
      setAssignUser(assignedToUserArray);

      setselectedCorrective(response.data);
      setBodyTxt('Select Assigned User');
      setViewState('assignedUserList');
    } catch (error) {
      setSelectedOption('No Submissions:');
      console.error('Error fetching forms:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const toggleNameSelection = (user: string[]) => {
    setselectedNames(prevSelectedUsers => {
      let updatedSelectedUsers;

      if (user.includes('All')) {
        updatedSelectedUsers = prevSelectedUsers.includes('All') ? [] : ['All'];
      } else {
        if (prevSelectedUsers.includes('All')) {
          updatedSelectedUsers = [user];
        } else {
          updatedSelectedUsers = prevSelectedUsers.includes(user)
            ? prevSelectedUsers.filter(u => u !== user)
            : [...prevSelectedUsers, user];
        }
      }

      const result = updatedSelectedUsers.includes('All')
        ? 'All'
        : updatedSelectedUsers.join('^~^');

      setSelectedSafetyPlan(result);

      handleSubMissionNext(selectProjectId, updatedSelectedUsers, selectedUsers);
      return updatedSelectedUsers;
    });
  };

  const getPtpList = async (projectID: number[]) => {
    //setProjectList([]);
    setselectedAsUsers([]);
    setSubmissionList([]);
    setAllSubmissionList([]);
    setCAProjectList([]);
    setSelectedItems([]);
    if (!user) return;
    setIsLoading(true);
    const endpoint = PTP_SUBMISSIONS_API;

    try {
      const response = await axios.get(endpoint, {
        headers: { Authorization: tokenKey },
      });

      const dataArray = response.data.data || [];
      console.log('Fetched Data Array:', dataArray);

      const projectArray = [
        { projectID: 0, name: 'All' },
        ...Array.from(
          new Map(
            dataArray.map((item: any) => [
              item.ptp.project.projectID,
              { projectID: item.ptp.project.projectID, name: item.ptp.project.name },
            ]),
          ).values(),
        ),
      ];

      const projectIDArray = Array.isArray(projectID) ? projectID : [];
      let filteredData = [];

      const showAllSubmissions = projectIDArray.includes(0);

      if (showAllSubmissions) {
        filteredData = dataArray;
      } else {
        filteredData = dataArray.filter((item: any) =>
          projectIDArray.includes(item.ptp.project.projectID),
        );
      }

      setTimeout(() => {
        setIsShow(true);
        setBodyTxt('Please Select Project');
      }, 800);
      setSubmissionList(filteredData);
      setAllSubmissionList(dataArray);
      setCAProjectList(projectArray);
      setSubmissionCount(filteredData.length);
      setfilteredList(filteredData);
      setBodyTxt('');
      setViewState('forms');
    } catch (error) {
      // Handle API errors
      setSelectedOption('No Submissions:');
      console.error('Error fetching PTP submissions:', error);
    } finally {
      // Stop loading spinner
      setIsLoading(false);
    }
  };

  const getCAList = async (projectID: string, user_ids: any) => {
    if (!user) return;

    try {
      const projectIDArray: any = projectID;
      let filteredData = [];

      const showAllSubmissions = projectIDArray.includes(0) || projectIDArray.length === 0;
      const includeAllUsers = user_ids.includes('All') || user_ids.length === 0;

      if (showAllSubmissions && includeAllUsers) {
        filteredData = allsubmissionList;
      } else if (showAllSubmissions) {
        filteredData = allsubmissionList.filter((item: any) => user_ids.includes(item.assignedTo));
      } else if (includeAllUsers) {
        filteredData = submissionList.filter((item: any) =>
          projectIDArray.includes(item.projectID),
        );
      } else {
        filteredData = submissionList.filter(
          (item: any) =>
            projectIDArray.includes(item.projectID) && user_ids.includes(item.assignedTo),
        );
      }

      setSubmissionCount(filteredData.length);
      setBodyTxt('');
      setViewState('forms');
      setfilteredList(filteredData);
    } catch (error) {
      //setSelectedOption('No Submissions:');
      console.error('Error fetching forms:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchPTPList = async (projectID: string, user_ids: any) => {
    if (!user) return;
    console.log('projectID', projectID);
    try {
      const projectIDArray: any = projectID;
      let filteredData = [];

      const showAllSubmissions = projectIDArray.includes(0) || projectIDArray.length === 0;
      const includeAllUsers = user_ids.includes('All') || user_ids.length === 0;
      console.log('allsubmissionList', submissionList);
      if (showAllSubmissions && includeAllUsers) {
        filteredData = allsubmissionList;
      } else if (showAllSubmissions) {
        filteredData = allsubmissionList.filter((item: any) => user_ids.includes(item.assignedTo));
      } else if (includeAllUsers) {
        filteredData = submissionList.filter((item: any) =>
          projectIDArray.includes(item.ptp.projectId),
        );
      } else {
        filteredData = submissionList.filter(
          (item: any) =>
            projectIDArray.includes(item.ptp.projectId) && user_ids.includes(item.assignedTo),
        );
      }

      setSubmissionCount(filteredData.length);
      setBodyTxt('');
      setViewState('forms');
      setfilteredList(filteredData);
    } catch (error) {
      setSelectedOption('No Submissions:');
      console.error('Error fetching forms:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleTimeChange = (time: string) => {
    setJobDetails(prevDetails => ({
      ...prevDetails,
      executionTime: time,
    }));
  };

  const updateJobDetail = (key: string, value: string) => {
    setJobDetails(prev => ({ ...prev, [key]: value }));
  };

  const handleScheduleJobSubmit = async () => {
    if (!validateForm()) return;
    if (jobDetails.executionTime === 'Select time') {
      errors.executionTime = 'Execution time is required.'; // Set the error message
    }
    message.loading({ content: 'Adding Job Schedule', key: 'addSchedule' }, 0);

    const endpoint = POST_SCHEDULE_JOBS_API.replace(':id', `${user.user_metadata.accountID}`);
    let jobFrequencyValue = '0';
    if (jobDetails.frequency === 'daily') {
      jobFrequencyValue = '0';
    } else if (jobDetails.frequency === 'weekly') {
      jobFrequencyValue = jobDetails.dayOfWeek;
    } else if (jobDetails.frequency === 'monthly') {
      jobFrequencyValue = jobDetails.dayOfMonth;
    }
    const requestBody = [
      {
        accountId: user.user_metadata.accountID,
        userId: user.user_id,
        activityType: activityType,
        projectIds: selectProjectId,
        safetyPrograms: selectedSafetyPlan,
        userIds: selectUser,
        jobTitle: jobDetails.title,
        jobDescription: jobDetails.description,
        jobFrequency: jobDetails.frequency,
        jobFrequencyValue: jobFrequencyValue,
        jobTime: jobDetails.executionTime,
        jobTimezone: jobDetails.timezone_txt,
        recipientEmails: jobDetails.recipientEmails,
        status: 'active',
      },
    ];

    console.log('requestBody:---->', requestBody);
    try {
      await axios.post(endpoint, requestBody, {
        headers: { Authorization: tokenKey },
      });

      setFlag(10);

      setTimeout(() => setIsModalOpen(false), 2000);
      message.success({ content: 'Job scheduled successfully!', key: 'addSchedule' }, 2);
      setTimeout(() => onClose(), 2000);
      setJobDetails({
        title: '',
        frequency: '',
        executionTime: '',
        description: '',
        recipientEmails: '',
        timezone_txt: 'America/Chicago',
        dayOfMonth: '',
        dayOfWeek: '',
      });
    } catch (error) {
      console.error('Error scheduling report:', error);
      setSelectedOption('Failed to schedule report. Please try again.');
    } finally {
      //setIsLoading(false);
    }
  };

  const toggleItemSelection = (id: any) => {
    setSelectedItems(prevSelectedItems => {
      let updatedSelectedItems;

      if (id === 0) {
        updatedSelectedItems = prevSelectedItems.includes(0) ? [] : [0];
      } else {
        updatedSelectedItems = prevSelectedItems.includes(id)
          ? prevSelectedItems.filter(itemId => itemId !== id)
          : [...prevSelectedItems, id];

        updatedSelectedItems = updatedSelectedItems.filter(itemId => itemId !== 0);
      }

      const result = updatedSelectedItems.includes(0) ? '' : updatedSelectedItems.join('^~^');

      handleSubMissionNext(updatedSelectedItems, selectedNames, selectedUsers);
      setBodyTxt('');
      setProjectId(result);

      return updatedSelectedItems;
    });
  };

  const togglePtpItemSelection = (id: any) => {
    console.log(id);
    if (activityType === 'ca_report') {
      setSelectedItems(prevSelectedItems => {
        let updatedSelectedItems;

        if (id === 0) {
          updatedSelectedItems = prevSelectedItems.includes(0) ? [] : [0];
        } else {
          updatedSelectedItems = prevSelectedItems.includes(id)
            ? prevSelectedItems.filter(itemId => itemId !== id)
            : [...prevSelectedItems, id];

          updatedSelectedItems = updatedSelectedItems.filter(itemId => itemId !== 0);
        }

        const result = updatedSelectedItems.includes(0) ? '' : updatedSelectedItems.join('^~^');

        handleNext(updatedSelectedItems, []);
        setBodyTxt('');
        setProjectId(result);

        return updatedSelectedItems;
      });
    } else {
      setSelectedItems(prevSelectedItems => {
        let updatedSelectedItems;

        if (id === 0) {
          updatedSelectedItems = prevSelectedItems.includes(0) ? [] : [0];
        } else {
          updatedSelectedItems = prevSelectedItems.includes(id)
            ? prevSelectedItems.filter(itemId => itemId !== id)
            : [...prevSelectedItems, id];

          updatedSelectedItems = updatedSelectedItems.filter(itemId => itemId !== 0);
        }

        const result = updatedSelectedItems.includes(0) ? '' : updatedSelectedItems.join('^~^');

        handleNext(updatedSelectedItems, []);
        setBodyTxt('');
        setProjectId(result);

        return updatedSelectedItems;
      });
    }
  };

  const toggleUserSelection = (user: any) => {
    setselectedAsUsers(prevSelectedUsers => {
      let updatedSelectedUsers;

      if (user === 'All') {
        updatedSelectedUsers = prevSelectedUsers.includes('All') ? [] : ['All'];
      } else {
        if (prevSelectedUsers.includes('All')) {
          updatedSelectedUsers = [user];
        } else {
          updatedSelectedUsers = prevSelectedUsers.includes(user)
            ? prevSelectedUsers.filter(u => u !== user)
            : [...prevSelectedUsers, user];
        }
      }

      const result = updatedSelectedUsers.includes('All')
        ? 'All'
        : updatedSelectedUsers.join('^~^');

      setSelectedUserId(result);

      if (activityType === 'ca_report') {
        handleNext(selectProjectId, updatedSelectedUsers);
      } else if (activityType === 'submissions_report') {
        handleSubMissionNext(selectProjectId, selectedNames, updatedSelectedUsers);
      } else if (activityType === 'ptp_submissions_report') {
        handleNext(selectProjectId, updatedSelectedUsers);
      }

      //handleNextUser(updatedSelectedUsers);
      return updatedSelectedUsers;
    });
  };

  const handleNext = (selectedItems: any, selectedUsers: any) => {
    setTimeout(() => {
      setIsShow(true);
      setBodyTxt('Please Select Project');
    }, 800);
    if (activityType === 'ptp_submissions_report') {
      fetchPTPList(selectedItems, selectedUsers);
    } else if (activityType === 'ca_report') {
      getCAList(selectedItems, selectedUsers);
    }
  };

  const handleSubMissionNext = (selectedItems: any, selectedForms: any, selectedUsers: any) => {
    setTimeout(() => {
      setIsShow(true);
      setBodyTxt('Please Select Project');
    }, 800);
    fetchFormsForProject(selectedItems, selectedForms, selectedUsers);
  };

  const openReport = () => {
    let safetyPlanName = selectedSafetyPlan;
    if (selectedSafetyPlan === 'All' || selectedSafetyPlan === null) {
      safetyPlanName = '';
    }
    let range = '';
    if (selectedRange === 0) {
      range = '';
    } else {
      range = selectedRange;
    }
    const reportUrl = API_URL;
    if (activityType === 'submissions_report') {
      setactivityType('submissions_report');
      const url =
        reportUrl +
        '/reports/' +
        Admin_Api_Key +
        '/submissions-export?userId=' +
        user.user_id +
        '&projectIds=' +
        selectProjectId +
        '&safetyPrograms=' +
        safetyPlanName +
        '&userIds=' +
        selectUser +
        '&daysRange=' +
        range;

      window.open(url, '_blank');
    } else if (activityType === 'ca_report') {
      setactivityType('ca_report');
      const url =
        reportUrl +
        '/reports/' +
        Admin_Api_Key +
        '/ca-export?userId=' +
        user.user_id +
        '&projectIds=' +
        selectProjectId +
        '&userIds=' +
        selectUser +
        '&daysRange=' +
        range;

      window.open(url, '_blank');
    } else if (activityType === 'ptp_submissions_report') {
      setactivityType('ptp_submissions_report');
      const url =
        reportUrl +
        '/reports/' +
        Admin_Api_Key +
        '/ptp-submissions-export?projectIds=' +
        selectProjectId +
        '&userIds=' +
        selectUser +
        '&daysRange=' +
        range;

      window.open(url, '_blank');
    }
    else if (activityType === 'goformz_report') {
      setactivityType('goformz_report');
      const url =
        reportUrl +
        '/reports/' +
        Admin_Api_Key +
        '/formzsubmissions-export?projectIds=' +
        selectProjectId +
        '&userIds=' +
        selectUser +
        '&daysRange=' +
        range;

      window.open(url, '_blank');
    }
   
  };

  const handleTimeRangeChange = (daysRange: any) => {
    const days = parseInt(daysRange, 10);
    console.log(daysRange, days);
    const now: any = new Date();
    let range = '';
    if (days === 0) {
      range = 'All';
    } else {
      range = daysRange;
    }

    console.log('testing', range, days);
    setSelectedRange(range);
    const list = filteredList;

    console.log(list);

    const currentDate = new Date();
    const startDate = new Date(currentDate);
    startDate.setDate(startDate.getDate() - daysRange);

    let filteredData = [];
    if (activityType === 'submissions_report') {
      if (days === 0) {
        filteredData = list;
      } else {
        const currentDate = new Date();
        const startDate = new Date(currentDate);
        startDate.setDate(startDate.getDate() - days);

        filteredData = list.filter(item => {
          const creationDate = new Date(item.creationDateTime);
          return creationDate >= startDate;
        });
      }
    } else if (activityType === 'ca_report') {
      if (days === 0) {
        filteredData = list;
      } else {
        const currentDate = new Date();
        const startDate = new Date(currentDate);
        startDate.setDate(startDate.getDate() - days);

        filteredData = list.filter(item => {
          const creationDate = new Date(item.creationDateTime);
          return creationDate >= startDate;
        });
      }
    } else if (activityType === 'ptp_submissions_report') {
      if (days === 0) {
        filteredData = list;
      } else {
        const currentDate = new Date();
        const startDate = new Date(currentDate);
        startDate.setDate(startDate.getDate() - days);

        filteredData = list.filter(item => {
          const creationDate = new Date(item.creationDateTime);
          return creationDate >= startDate;
        });
      }
    }
    else if (activityType === 'goformz_report') {
      if (days === 0) {
        filteredData = list;
      } else {
        const currentDate = new Date();
        const startDate = new Date(currentDate);
        startDate.setDate(startDate.getDate() - days);

        filteredData = list.filter(item => {
          const creationDate = new Date(item.creationDateTime);
          return creationDate >= startDate;
        });
      }
    }

    setSubmissionCount(filteredData.length);
  };

  const toggleCollapse = (name: string) => {
    setCollapsed(prevState => ({
      ...prevState,
      [name]: !prevState[name],
    }));
  };

  const toggleParentCollapse = (name: string) => {
    setParentCollapsed(prevState => ({
      ...prevState,
      [name]: !prevState[name],
    }));
  };
  const [showModal, setShowModal] = useState(false);

  const handleBackClick = () => {
    setShowModal(true);
  };

  const fetchAssosiates = async () => {
    if (!user) return;

    const endpoint = FETCH_USER_TEAM_API.replace(':userID', `${user.user_id}`);

    try {
      const response = await axios.get(endpoint, {
        headers: { Authorization: tokenKey },
      });
      const projects = response.data;
      console.log(projects);
      const newEmails = projects.map((item: any) => item.email);
      //setEmailList(prevEmails => [...prevEmails, ...newEmails]);
    } catch (error) {
      console.error('Error fetching projects:', error);
    } finally {
    }
  };

  const handleBlur = () => {
    fetchAssosiates();
    const emails = jobDetails.recipientEmails
      .split(',')
      .map(email => email.trim())
      .filter(email => email && isValidEmail(email));

    const updatedEmailList = [...emailList];

    emails.forEach(email => {
      if (!updatedEmailList.includes(email)) {
        updatedEmailList.push(email);
      }
    });

    setEmailList(updatedEmailList);

    updateJobDetail('recipientEmails', emails.join(', '));
  };

  // Email validation function
  const isValidEmail = (email: string) => {
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    return emailPattern.test(email);
  };

  const handleSaveEmails = (emails: string[]) => {
    const emailString = emails.filter(email => email.trim() !== '').join(', ');
    setJobDetails(prev => ({
      ...prev,
      recipientEmails: emailString,
    }));
  };

  const uniqueSafetyPlans = Array.from(
    new Set(safetyPlanList.map((item: any) => item.safetyPlanName)),
  );

  const handleSafetyPlanSelection = (safetyPlanName: string, list: any[]) => {

    console.log('safetyPlanName:000--->',safetyPlanName);
    if (safetyPlanName === 'All') {
      setselectedNames(['All']);
      return;
    }
  
    setselectedNames(prevSelectedNames => {
      let updatedNames = [...prevSelectedNames];
  
      if (updatedNames.includes(safetyPlanName)) {
        // Deselect the safety plan and all its children
        updatedNames = updatedNames.filter(name => name !== safetyPlanName);
  
        const plan = list.find(plan => plan.safetyPlanName === safetyPlanName);
        if (plan) {
          plan.parentForms.forEach((parent:any) => {
            updatedNames = updatedNames.filter(name => name !== parent.parentForm);
            parent.formNames.forEach((formName:any) => {
              updatedNames = updatedNames.filter(name => name !== formName);
            });
          });
          plan.directFormNames.forEach((formName:any) => {
            updatedNames = updatedNames.filter(name => name !== formName);
          });
        }
      } else {
        // Select the safety plan and all its children
        updatedNames.push(safetyPlanName);
  
        const plan = list.find(plan => plan.safetyPlanName === safetyPlanName);
        if (plan) {
          plan.parentForms.forEach((parent:any) => {
            if (!updatedNames.includes(parent.parentForm)) {
              updatedNames.push(parent.parentForm);
            }
            parent.formNames.forEach((formName:any) => {
              if (!updatedNames.includes(formName)) {
                updatedNames.push(formName);
              }
            });
          });
          plan.directFormNames.forEach((formName:any) => {
            if (!updatedNames.includes(formName)) {
              updatedNames.push(formName);
            }
          });
        }
      }
  
      return updatedNames;
    });
  };
  

  const handleParentFormSelection = (parentForm: string, safetyPlanName: string) => {
    setselectedNames(prevSelectedNames => {
      const isParentSelected = prevSelectedNames.includes(parentForm);
      let updatedSelectedNames = [...prevSelectedNames];
  
      const plan = uniqueSafetyPlanList.find(plan => plan.safetyPlanName === safetyPlanName);
      if (!plan) return updatedSelectedNames;
  
      if (isParentSelected) {
        // Deselect the parent form and all its child forms
        updatedSelectedNames = updatedSelectedNames.filter(name => name !== parentForm);
        plan.parentForms.forEach(parent => {
          if (parent.parentForm === parentForm) {
            parent.formNames.forEach(formName => {
              updatedSelectedNames = updatedSelectedNames.filter(name => name !== formName);
            });
          }
        });
      } else {
        // Select the parent form and all its child forms
        updatedSelectedNames.push(parentForm);
        plan.parentForms.forEach(parent => {
          if (parent.parentForm === parentForm) {
            parent.formNames.forEach(formName => {
              if (!updatedSelectedNames.includes(formName)) {
                updatedSelectedNames.push(formName);
              }
            });
          }
        });
  
        // Ensure the safety plan remains selected
        if (!updatedSelectedNames.includes(safetyPlanName)) {
          updatedSelectedNames.push(safetyPlanName);
        }
      }
  
      return updatedSelectedNames;
    });
  };
  

  const handleFormSelection = (formName: string, parentForm: string, safetyPlanName: string) => {
    setselectedNames(prevSelectedNames => {
      let updatedSelectedNames = [...prevSelectedNames];
      const isFormSelected = updatedSelectedNames.includes(formName);
  
      if (isFormSelected) {
        // Deselect the child form
        updatedSelectedNames = updatedSelectedNames.filter(name => name !== formName);
      } else {
        // Select the child form
        updatedSelectedNames.push(formName);
      }
  
      // Ensure the parent form and safety plan remain selected
      if (!isFormSelected) {
        if (!updatedSelectedNames.includes(parentForm)) {
          updatedSelectedNames.push(parentForm);
        }
        if (!updatedSelectedNames.includes(safetyPlanName)) {
          updatedSelectedNames.push(safetyPlanName);
        }
      }
  
      return updatedSelectedNames;
    });
  };
  
  function getUniqueSafetyPlanList(safetyPlanList: any[]) {
    const uniqueSafetyPlans = new Map();

    safetyPlanList.forEach(item => {
      if (!uniqueSafetyPlans.has(item.safetyPlanName)) {
        uniqueSafetyPlans.set(item.safetyPlanName, {
          safetyPlanName: item.safetyPlanName,
          parentForms: new Map(),
          directFormNames: new Set(),
        });
      }

      const safetyPlan = uniqueSafetyPlans.get(item.safetyPlanName);

      // Handle parent forms
      if (item.parentForm) {
        if (!safetyPlan.parentForms.has(item.parentForm)) {
          safetyPlan.parentForms.set(item.parentForm, new Set());
        }
        if (item.formName) {
          safetyPlan.parentForms.get(item.parentForm).add(item.formName);
        }
      } else if (item.formName) {
        safetyPlan.directFormNames.add(item.formName);
      }
    });

    // Convert the unique safety plans map to an array
    const formattedPlans = Array.from(uniqueSafetyPlans.values()).map(plan => ({
      safetyPlanName: plan.safetyPlanName,
      parentForms: Array.from(plan.parentForms.entries() as [any, Set<string>][]).map(
        ([parentForm, formNames]) => ({
          parentForm, // Parent form name
          formNames: Array.from(formNames), // Convert Set to array
        }),
      ),
      directFormNames: Array.from(plan.directFormNames), // Convert Set to array
    }));

    // Add the "All" option at the beginning of the array
    formattedPlans.unshift({
      safetyPlanName: 'All',
      parentForms: [],
      directFormNames: [],
    });

    return formattedPlans;
  }

  const uniqueSafetyPlanList = getUniqueSafetyPlanList(safetyPlanList);
  console.log('uniqueSafetyPlanList-->', uniqueSafetyPlanList);
  function getAllNames(safetyPlanList: any[]) {
    const allNames: string[] = [];
    safetyPlanList.forEach(plan => {
      allNames.push(plan.safetyPlanName); // Add safety plan name
      plan.parentForms.forEach((parent: any) => {
        allNames.push(parent.parentForm); // Add parent forms
        allNames.push(...parent.formNames); // Add child form names
      });
      allNames.push(...plan.directFormNames); // Add direct form names
    });
    return allNames;
  }

  const generateSafetyProgramsString = () => {
    // If "All" is selected, return an empty string
    if (selectedNames.length === 1 && selectedNames.includes('All')) {
      return '';
    }
  
    return uniqueSafetyPlanList
      .filter(plan => selectedNames.includes(plan.safetyPlanName)) // Include only selected safety plans
      .map(plan => {
        const parentFormDetails = plan.parentForms
          .filter(parent => selectedNames.includes(parent.parentForm)) // Include only selected parent forms
          .map(parent => {
            const selectedFormNames = parent.formNames.filter(formName =>
              selectedNames.includes(formName),
            );
  
            // Only include the parent if it or any of its children are selected
            return selectedFormNames.length
              ? `${parent.parentForm}|~|${selectedFormNames.join('|~|')}`
              : `${parent.parentForm}`;
          })
          .filter(parentDetail => selectedNames.some(name => parentDetail.includes(name))) // Exclude unselected parent forms
          .join('#~#');
  
        // Handle forms directly under the safety plan (parentForm is null)
        const directFormDetails = plan.directFormNames
          .filter(formName => selectedNames.includes(formName)) // Include only selected direct forms
          .join('|~|');
  
        // Combine parent form details and direct forms under safety plan
        const combinedDetails = [parentFormDetails, directFormDetails]
          .filter(details => details) // Remove empty sections
          .join('#~#');
  
        return combinedDetails
          ? `${plan.safetyPlanName}$~$${combinedDetails}` // Include safety plan only if it has selected items
          : '';
      })
      .filter(planString => planString) // Remove empty safety plans
      .join('^~^'); // Combine all safety plans
  };
  
  

  // Example Usage
  const safetyPrograms = generateSafetyProgramsString();
  console.log('safetyPrograms:-->', safetyPrograms);
  useEffect(() => {
    const safetyProgramsString = generateSafetyProgramsString();
    if (selectedSafetyPlan !== safetyProgramsString) {
      setSelectedSafetyPlan(safetyProgramsString);
      handleSubMissionNext(selectProjectId, safetyProgramsString, selectedUsers);
    }
  }, [selectedNames]);

  return (
    <>
      {openModal && (
        <div className="sa__box md-w-100">
          <div className="alert-heading">
            <button className="back-button " onClick={handleBackClick}>
              ←
            </button>
            <h4 className="sa__box-heading-title">Create New Report</h4>

            {showModal && (
              <div className="alert">
                <div className="alert-content">
                  <h2>Confirm</h2>
                  <p>Do you want to save this report before leaving?</p>
                  <button
                    className="ant-btn ant-btn-primary"
                    onClick={() => {
                      //onSave(); // Handle save action
                      setShowModal(false);
                      setIsModalOpen(true);
                    }}
                  >
                    Save
                  </button>
                  <button
                    className="ant-btn"
                    onClick={() => {
                      setShowModal(false);
                      setFlag(3);
                      onClose();
                    }}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            )}
          </div>
          <div className="sa__box-content">
            <div className="">
              <div className="message-box">
                <div className="tab-container">
                  {reportOptions.map(option => (
                    <button
                      key={option.id}
                      className={`tab-button ${activeTab === option.label ? 'active' : ''}`}
                      onClick={() => handleOptionClick(option.label)}
                    >
                      {option.label}
                    </button>
                  ))}
                </div>
                {isLoading ? (
                  <div>
                    <LoadingIndicator />
                  </div>
                ) : (
                  <div className="up-container">
                    <hr />
                    <div className="right-panel">
                      {activityType === 'submissions_report' ? (
                        <>
                          {' '}
                          <div className="project-panel">
                            <p className="initialDesc">Projects</p>
                            <div>
                              {projectList.map(project => (
                                <button
                                  key={project.projectID}
                                  className="option-button"
                                  onClick={() => toggleItemSelection(project.projectID)}
                                >
                                  <span>{project.name}</span>
                                  <input
                                    type="checkbox"
                                    id={`report-${project.projectID}`}
                                    checked={selectedItems.includes(project.projectID)}
                                    onChange={() => toggleItemSelection(project.projectID)}
                                    onClick={e => e.stopPropagation()}
                                    //disabled={selectedItems.includes(0) && project.projectID !== 0}
                                  />
                                </button>
                              ))}
                            </div>
                          </div>
                          <div className="safetyPg-panel">
                            <p className="initialDesc">Safety Programs</p>
                            <div className="safety-plan-container">
                              <div className="safety-plan-list">
                                {uniqueSafetyPlanList.map((plan, index) => (
                                  <div
                                    key={`plan-${plan.safetyPlanName}-${index}`}
                                    className="safety-plan-item"
                                  >
                                    {/* Safety Plan Level */}
                                    <div className="option-button safety-plan-name">
                                      {/* Hide collapse icon for "All" */}
                                      {plan.safetyPlanName !== 'All' && (
                                        <span onClick={() => toggleCollapse(plan.safetyPlanName)}>
                                          {collapsed[plan.safetyPlanName] ? '►' : '▼'}
                                        </span>
                                      )}
                                      <span
                                        onClick={() =>
                                          handleSafetyPlanSelection(
                                            plan.safetyPlanName,
                                            uniqueSafetyPlanList,
                                          )
                                        }
                                      >
                                        {plan.safetyPlanName}
                                      </span>
                                      <input
                                        type="checkbox"
                                        checked={
                                          plan.safetyPlanName === 'All' || plan.safetyPlanName === ''
                                            ? selectedNames.length === 1 &&
                                              selectedNames.includes('All')
                                            : selectedNames.includes(plan.safetyPlanName)
                                        }
                                        onChange={() =>
                                          handleSafetyPlanSelection(
                                            plan.safetyPlanName,
                                            uniqueSafetyPlanList,
                                          )
                                        }
                                      />
                                    </div>

                                    {/* Parent Forms Level */}
                                    {!collapsed[plan.safetyPlanName] &&
                                      plan.parentForms.length > 0 &&
                                      plan.safetyPlanName !== 'All' && (
                                        <div
                                          className="parent-form-list"
                                          style={{ marginLeft: '20px' }}
                                        >
                                          {plan.parentForms.map((parent, idx) => (
                                            <div
                                              key={`parent-${plan.safetyPlanName}-${parent.parentForm}-${idx}`}
                                            >
                                              <div className="option-button parent-form-name">
                                                <span
                                                  onClick={() =>
                                                    toggleParentCollapse(parent.parentForm)
                                                  }
                                                >
                                                  {parentCollapsed[parent.parentForm] ? '►' : '▼'}
                                                </span>
                                                <span
                                                  onClick={() =>
                                                    handleParentFormSelection(
                                                      parent.parentForm,
                                                      plan.safetyPlanName,
                                                    )
                                                  }
                                                >
                                                  {parent.parentForm}
                                                </span>
                                                <input
                                                  type="checkbox"
                                                  checked={selectedNames.includes(
                                                    parent.parentForm,
                                                  )}
                                                  onChange={() =>
                                                    handleParentFormSelection(
                                                      parent.parentForm,
                                                      plan.safetyPlanName,
                                                    )
                                                  }
                                                />
                                              </div>

                                              {/* Form Names under Parent Form */}
                                              {!parentCollapsed[parent.parentForm] && (
                                                <div
                                                  className="form-name-list"
                                                  style={{ marginLeft: '20px' }}
                                                >
                                                  {parent.formNames.map((formName, idy) => (
                                                    <div
                                                      key={`form-${plan.safetyPlanName}-${parent.parentForm}-${formName}-${idy}`}
                                                    >
                                                      <button className="option-button">
                                                        <span
                                                          onClick={() =>
                                                            handleFormSelection(
                                                              formName,
                                                              parent.parentForm,
                                                              plan.safetyPlanName,
                                                            )
                                                          }
                                                        >
                                                          {formName}
                                                        </span>
                                                        <input
                                                          type="checkbox"
                                                          checked={selectedNames.includes(formName)}
                                                          onChange={() =>
                                                            handleFormSelection(
                                                              formName,
                                                              parent.parentForm,
                                                              plan.safetyPlanName,
                                                            )
                                                          }
                                                        />
                                                      </button>
                                                    </div>
                                                  ))}
                                                </div>
                                              )}
                                            </div>
                                          ))}
                                        </div>
                                      )}

                                    {/* Direct Form Names */}
                                    {!collapsed[plan.safetyPlanName] &&
                                      plan.directFormNames.length > 0 &&
                                      plan.safetyPlanName !== 'All' && (
                                        <div
                                          className="form-name-list"
                                          style={{ marginLeft: '20px' }}
                                        >
                                          {plan.directFormNames.map((formName: any, idx) => (
                                            <div
                                              key={`direct-${plan.safetyPlanName}-${formName}-${idx}`}
                                            >
                                              <button className="option-button">
                                                <span onClick={() =>
                                                    handleFormSelection(
                                                      formName,
                                                      '',
                                                      plan.safetyPlanName,
                                                    )
                                                  }>{formName}</span>
                                                <input
                                                  type="checkbox"
                                                  checked={selectedNames.includes(formName)}
                                                  onChange={() =>
                                                    handleFormSelection(
                                                      formName,
                                                      '',
                                                      plan.safetyPlanName,
                                                    )
                                                  }
                                                />
                                              </button>
                                            </div>
                                          ))}
                                        </div>
                                      )}
                                  </div>
                                ))}
                              </div>
                            </div>
                          </div>
                          <div className="users-panel">
                            <p className="initialDesc">Users</p>
                            <div className="user-container">
                              <div className="user-list">
                                {users.map((user, index) => (
                                  <button
                                    key={index}
                                    className="option-button"
                                    onClick={() => toggleUserSelection(user.value)}
                                  >
                                    <span>{user.label}</span>
                                    <input
                                      type="checkbox"
                                      checked={selectedUsers.includes(user.value)}
                                      onClick={e => e.stopPropagation()}
                                      // disabled={
                                      //   selectedUsers.includes('All') && user.value !== 'All'
                                      // }
                                      onChange={() => toggleUserSelection(user.value)}
                                    />
                                  </button>
                                ))}
                              </div>
                            </div>
                          </div>
                          <div className="period-panel">
                            <div className="time-range-container">
                              <label htmlFor="timeRangeDropdown" className="time-range-label">
                                Submission/Creation Period
                              </label>
                              <div className="dropdown-wrapper">
                                <select
                                  id="timeRangeDropdown"
                                  onChange={e => handleTimeRangeChange(e.target.value)}
                                  defaultValue="0"
                                  className="custom-dropdown"
                                >
                                  <option value="0">All Days</option>
                                  <option value="7">Past 7 Days</option>
                                  <option value="30">Past 30 Days</option>
                                  <option value="60">Past 60 Days</option>
                                  <option value="90">Past 90 Days</option>
                                  <option value="120">Past 120 Days</option>
                                </select>
                                <span className="dropdown-arrow">▼</span>
                              </div>
                            </div>
                          </div>
                        </>
                      ) : activityType === 'ca_report' ? (
                        <>
                          <div className="project-panel">
                            <p className="initialDesc">Projects</p>
                            <div>
                              {caprojectList.map(project => (
                                <button
                                  key={project.projectID}
                                  className="option-button"
                                  onClick={() => togglePtpItemSelection(project.projectID)}
                                >
                                  <span>{project.name}</span>
                                  <input
                                    type="checkbox"
                                    id={`report-${project.projectID}`}
                                    checked={selectedItems.includes(project.projectID)}
                                    onChange={() => togglePtpItemSelection(project.projectID)}
                                    onClick={e => e.stopPropagation()}
                                    // disabled={selectedItems.includes(0) && project.projectID !== 0}
                                  />
                                </button>
                              ))}
                            </div>
                          </div>
                          <div className="users-panel">
                            <p className="initialDesc">Users</p>
                            <div className="assigned-user-container">
                              <div className="assigned-user-list">
                                {AssignUser.map((user, index) => (
                                  <button
                                    key={index}
                                    className="option-button"
                                    onClick={() => toggleUserSelection(user.value)}
                                  >
                                    <span>{user.label || ''}</span>
                                    <input
                                      type="checkbox"
                                      id={`report-${user.value}`}
                                      checked={selectedUsers.includes(user.value)}
                                      onChange={() => toggleUserSelection(user.value)}
                                      onClick={e => e.stopPropagation()}
                                      // disabled={
                                      //   selectedUsers.includes('All') && user.value !== 'All'
                                      // }
                                    />
                                  </button>
                                ))}
                              </div>
                            </div>
                          </div>
                          <div className="period-panel">
                            <div className="time-range-container">
                              <label htmlFor="timeRangeDropdown" className="time-range-label">
                                Submission/Creation Period
                              </label>
                              <div className="dropdown-wrapper">
                                <select
                                  id="timeRangeDropdown"
                                  onChange={e => handleTimeRangeChange(e.target.value)}
                                  defaultValue="0"
                                  className="custom-dropdown"
                                >
                                  <option value="0">All Days</option>
                                  <option value="7">Past 7 Days</option>
                                  <option value="30">Past 30 Days</option>
                                  <option value="60">Past 60 Days</option>
                                  <option value="90">Past 90 Days</option>
                                  <option value="120">Past 120 Days</option>
                                </select>
                                <span className="dropdown-arrow">▼</span>
                              </div>
                            </div>
                          </div>
                        </> // Replace with desired text
                      ) : activityType === 'ptp_submissions_report' ? (
                        <>
                          <div className="project-panel">
                            <p className="initialDesc">Projects</p>
                            <div>
                              {caprojectList.map(project => (
                                <button
                                  key={project.projectID}
                                  className="option-button"
                                  onClick={() => togglePtpItemSelection(project.projectID)}
                                >
                                  <span>{project.name}</span>
                                  <input
                                    type="checkbox"
                                    id={`report-${project.projectID}`}
                                    checked={selectedItems.includes(project.projectID)}
                                    onChange={() => togglePtpItemSelection(project.projectID)}
                                    onClick={e => e.stopPropagation()}
                                    // disabled={selectedItems.includes(0) && project.projectID !== 0}
                                  />
                                </button>
                              ))}
                            </div>
                          </div>
                          <div className="users-panel">
                            <p className="initialDesc">Users</p>
                            <div className="user-container">
                              <div className="user-list">
                                {users.map((user, index) => (
                                  <button
                                    key={index}
                                    className="option-button"
                                    onClick={() => toggleUserSelection(user.value)}
                                  >
                                    <span>{user.label}</span>
                                    <input
                                      type="checkbox"
                                      checked={selectedUsers.includes(user.value)}
                                      // disabled={
                                      //   selectedUsers.includes('All') && user.value !== 'All'
                                      // }
                                      onClick={e => e.stopPropagation()}
                                      onChange={() => toggleUserSelection(user.value)}
                                    />
                                  </button>
                                ))}
                              </div>
                            </div>
                          </div>
                          <div className="period-panel">
                            <div className="time-range-container">
                              <label htmlFor="timeRangeDropdown" className="time-range-label">
                                Submission/Creation Period
                              </label>
                              <div className="dropdown-wrapper">
                                <select
                                  id="timeRangeDropdown"
                                  onChange={e => handleTimeRangeChange(e.target.value)}
                                  defaultValue="0"
                                  className="custom-dropdown"
                                >
                                  <option value="0">All Days</option>
                                  <option value="7">Past 7 Days</option>
                                  <option value="30">Past 30 Days</option>
                                  <option value="60">Past 60 Days</option>
                                  <option value="90">Past 90 Days</option>
                                  <option value="120">Past 120 Days</option>
                                </select>
                                <span className="dropdown-arrow">▼</span>
                              </div>
                            </div>
                          </div>
                        </>
                      ) : activityType === 'goformz_report' ? (
                        <>
                          <div className="project-panel">
                            <p className="initialDesc">Projects</p>
                            <div>
                              {caprojectList.map(project => (
                                <button
                                  key={project.projectID}
                                  className="option-button"
                                  onClick={() => togglePtpItemSelection(project.projectID)}
                                >
                                  <span>{project.name}</span>
                                  <input
                                    type="checkbox"
                                    id={`report-${project.projectID}`}
                                    checked={selectedItems.includes(project.projectID)}
                                    onChange={() => togglePtpItemSelection(project.projectID)}
                                    onClick={e => e.stopPropagation()}
                                    // disabled={selectedItems.includes(0) && project.projectID !== 0}
                                  />
                                </button>
                              ))}
                            </div>
                          </div>
                         
                          <div className="period-panel">
                            <div className="time-range-container">
                              <label htmlFor="timeRangeDropdown" className="time-range-label">
                                Submission/Creation Period
                              </label>
                              <div className="dropdown-wrapper">
                                <select
                                  id="timeRangeDropdown"
                                  onChange={e => handleTimeRangeChange(e.target.value)}
                                  defaultValue="0"
                                  className="custom-dropdown"
                                >
                                  <option value="0">All Days</option>
                                  <option value="7">Past 7 Days</option>
                                  <option value="30">Past 30 Days</option>
                                  <option value="60">Past 60 Days</option>
                                  <option value="90">Past 90 Days</option>
                                  <option value="120">Past 120 Days</option>
                                </select>
                                <span className="dropdown-arrow">▼</span>
                              </div>
                            </div>
                          </div>
                        </>
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                )}

                {/* <button className="close-button" onClick={toggleMessageBox}>
              Close
            </button> */}
              </div>
            </div>
            {isShow ? (
              <div className="sa__box-content">
                <hr />
                {viewState === 'nodata' ? (
                  <div>
                    <h3>No Programs Found in this project please choose another one..</h3>
                  </div>
                ) : (
                  ''
                )}
                <>
                  <div className="container">
                    <div className="left-part">
                      <div className="countHead">
                        <h2>
                          Total {selectedOption.replace('Report', '').trim()}: {submissionCount}
                        </h2>
                      </div>
                      {submissionCount && submissionCount > 0 ? (
                        <div>
                          <h4 className="countHead" style={{ marginBottom: '15px' }}>
                            Your report is ready.{' '}
                            <a href="#" onClick={openReport}>
                              Click here
                            </a>{' '}
                            to view it.
                          </h4>
                        </div>
                      ) : (
                        ''
                      )}
                      <button
                        className="ant-btn ant-btn-primary"
                        onClick={() => setIsModalOpen(true)}
                        style={{ cursor: 'pointer' }}
                      >
                        Save
                      </button>
                    </div>
                    <div className="right-part">
                      {isModalOpen && (
                        <div className="modal-overlay">
                          <div className="modal-contentt">
                            <div
                              style={{
                                position: 'relative',
                              }}
                            >
                              <h2 style={{ margin: '0' }}>Schedule Job</h2>
                              <hr />
                              <button
                                onClick={() => resetState()}
                                style={{
                                  position: 'absolute',
                                  top: '0px',
                                  right: '0px',
                                  backgroundColor: '#fff',
                                  color: 'black',
                                  border: 'none',

                                  cursor: 'pointer',
                                }}
                              >
                                X
                              </button>
                            </div>
                            {successMessage && (
                              <div className="success-toast">{successMessage}</div>
                            )}
                            <form className="job-schedule-form">
                              <label className="from-label">Scheduled Report Title</label>
                              <input
                                type="text"
                                placeholder="Enter Title"
                                value={jobDetails.title}
                                onChange={e => updateJobDetail('title', e.target.value)}
                                required
                              />
                              {errors.title && <span className="error">{errors.title}</span>}

                              <div className="button-group-label">
                                <div className="left-input">
                                  <label className="from-label">Report Frequency</label>
                                  <select
                                    value={jobDetails.frequency}
                                    onChange={e => updateJobDetail('frequency', e.target.value)}
                                    required
                                  >
                                    <option value="" disabled>
                                      Select Frequency
                                    </option>
                                    <option value="daily">Daily</option>
                                    <option value="weekly">Weekly</option>
                                    <option value="monthly">Monthly</option>
                                  </select>
                                  {errors.frequency && (
                                    <span className="error">{errors.frequency}</span>
                                  )}
                                </div>
                                <div className="right-input">
                                  {jobDetails.frequency === 'monthly' && (
                                    <>
                                      <label className="from-label">Select Day of Month</label>
                                      <select
                                        value={jobDetails.dayOfMonth}
                                        onChange={e =>
                                          updateJobDetail('dayOfMonth', e.target.value)
                                        }
                                        required
                                      >
                                        <option value="" disabled>
                                          Select Date
                                        </option>
                                        {daysOfMonth.map(day => (
                                          <option key={day} value={day}>
                                            {day}
                                          </option>
                                        ))}
                                      </select>
                                      {errors.dayOfMonth && (
                                        <span className="error">{errors.dayOfMonth}</span>
                                      )}
                                    </>
                                  )}

                                  {jobDetails.frequency === 'weekly' && (
                                    <>
                                      <label className="from-label">Select Day of Week</label>
                                      <select
                                        value={jobDetails.dayOfWeek}
                                        onChange={e => updateJobDetail('dayOfWeek', e.target.value)}
                                        required
                                      >
                                        <option value="" disabled>
                                          Select Day
                                        </option>
                                        {daysOfWeek.map(day => (
                                          <option key={day} value={day.toLowerCase()}>
                                            {day}
                                          </option>
                                        ))}
                                      </select>
                                      {errors.dayOfWeek && (
                                        <span className="error">{errors.dayOfWeek}</span>
                                      )}
                                    </>
                                  )}
                                </div>
                              </div>
                              <div className="button-group-label">
                                <div className="left-input">
                                  <label className="from-label">Timezone</label>
                                  <select
                                    value={jobDetails.timezone_txt}
                                    onChange={e => updateJobDetail('timezone_txt', e.target.value)}
                                  >
                                    {timezones.map(tz => (
                                      <option key={tz.value} value={tz.value}>
                                        {tz.value}
                                      </option>
                                    ))}
                                  </select>
                                  {errors.timezone && (
                                    <span className="error">{errors.timezone}</span>
                                  )}
                                </div>
                                <div className="left-input">
                                  <label className="from-label">Report Execution Time</label>
                                  <TimeDropdown onTimeChange={handleTimeChange} />
                                  {errors.executionTime && (
                                    <span className="error">{errors.executionTime}</span>
                                  )}
                                </div>
                              </div>
                              <label className="from-label">Description</label>
                              <textarea
                                placeholder="Enter Description"
                                value={jobDetails.description}
                                onChange={e => updateJobDetail('description', e.target.value)}
                                required
                              />
                              {errors.description && (
                                <span className="error">{errors.description}</span>
                              )}
                              <div
                                style={{
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                  alignItems: 'center',
                                  marginBottom: '-6px',
                                }}
                              >
                                <label className="from-label">Recipient Email(s)</label>

                                <a href="#" onClick={() => setModalVisible(true)}>
                                  Select Email(s)
                                </a>
                              </div>

                              <textarea
                                placeholder="Recipient Email(s)"
                                value={jobDetails.recipientEmails}
                                onChange={e => updateJobDetail('recipientEmails', e.target.value)}
                                onBlur={handleBlur}
                                required
                                style={{
                                  width: '100%',
                                  padding: '8px',
                                  fontSize: '1rem',
                                  borderRadius: '4px',
                                  border: '1px solid #ccc',
                                }}
                              />

                              {errors.recipientEmails && (
                                <span className="error">{errors.recipientEmails}</span>
                              )}
                              <span className="txt_comma">
                                Add multiple email addresses separated by comma (,)
                              </span>

                              <div className="button-group-label">
                                <button
                                  type="button"
                                  onClick={handleScheduleJobSubmit}
                                  className="submit-button"
                                >
                                  Submit
                                </button>
                                <button
                                  type="button"
                                  onClick={() => resetState()}
                                  className="close-button"
                                >
                                  Cancel
                                </button>
                              </div>
                            </form>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </>
              </div>
            ) : (
              ''
            )}
          </div>
        </div>
      )}
      <EmailModal
        visible={isModalVisible}
        emailList={emailList}
        selectedEmails={jobDetails.recipientEmails.split(',').map(email => email.trim())}
        onClose={() => setModalVisible(false)}
        onSave={handleSaveEmails}
      />
    </>
  );
};

export default ContactUsButton;
