import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import { IAppState } from '@rdx/root.reducer';
import { ErrorMessage } from '@shared/types/utils.types';
import { getSessionUser } from '@app/session/session.selector';
import { fetchFormzAction } from '@app/back-office/goFormz/state/goformz.action';
import GoFormzComponent from '../view/goformz.component';
import { FETCH_FORMZ_SUBMISSION,FETCH_ACCOUNTS_PROJECTS_API ,FETCH_USER_PROJECTS_API} from '@constants/endpoints';
import Cookies from 'js-cookie';
import CenteredLoader from '@components/ui/accordian/spinner';

interface IFormzLockedProps {
  user?: any;
  isLoading: boolean;
  errorMessages: { [key: string]: ErrorMessage | undefined };
  schedule: any[];
  fetchFormzAction: (payload: { accountID: number; userID: string }) => void;
}



const GoFormzLocked: React.FC<IFormzLockedProps> = ({
  user,
  isLoading,
  schedule,
  fetchFormzAction,
}) => {
  const [loading, setLoading] = useState(false);
const [lists, setLists] = useState<any[]>([]);
const [projectList, setProjectLists] = useState<any[]>([]);
const tokenKey = `Bearer ${Cookies.get('sessionKey')}`;
  useEffect(() => {
   
      
        fetchAccountProject();
    
  }, []);

  console.log('User:', user);

  const fetchAccountProject = async () => {
    setLoading(true);
    if (!user) return;

    const endpoint = FETCH_USER_PROJECTS_API.replace(':accountID', `${user.user_metadata.accountID}`).replace(':userID',`${user.user_id}`);

    try {
      const response = await axios.get(endpoint, {
        headers: { Authorization: tokenKey },
      });
      console.log('Formatted Schedule with Project Namesss:', response.data);
      setProjectLists(response.data);
      fetchGoformz(response.data);
    } catch (error) {
      
      console.error('Error fetching forms:', error);
    } finally {
      //setIsLoading(false);
    }
  };

  const fetchGoformz = async (projectList:any[]) => {
    if (!user) return;
  
    const endpoint = FETCH_FORMZ_SUBMISSION.replace(':accountID', `${user.user_metadata.accountID}`);
  
    
  
    try {
      const response = await axios.get(endpoint, {
        headers: { Authorization: tokenKey },
      });
      
      if (response.data && Array.isArray(response.data)) {
        
        const formattedData = response.data.map((item: any) => {
          const project = projectList.find((proj: any) => proj.projectID === item.projectId);
          
        
          return {
            projectName: project ? project.name : `Unknown Project (${item.projectId})`,
            accountId: item.accountId,
            formName: item.formName,
            creationDateTime: item.creationDateTime,
            formzUserName: item.formzUserName,
            formzPdfUrl: item.formzPdfUrl,
          };
        });
  
        setLists(formattedData);
  
        console.log('Formatted Schedule with Project Names:', formattedData);
      } else {
        console.error('Unexpected data format from API:', response.data);
      }
    } catch (error) {
      console.error('Error fetching forms:', error);
    } finally {
      setLoading(false);
    }
  };
  

  return (
    <>
      <GoFormzComponent data={lists} isLoading={loading}/>
    </>
  );
};

const mapStateToProps = (state: IAppState) => ({
  user: getSessionUser(state),
  ///isLoading: state.loading, // Adjust this as per your Redux structure
  //schedule: state., // Adjust this as per your Redux structure
});

const mapDispatchToProps = {
  fetchFormzAction: fetchFormzAction.request,
};

export const FormzLockedContainer = connect(mapStateToProps, mapDispatchToProps)(GoFormzLocked);
