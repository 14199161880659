// ANTD COMPONENTS
import { Table, Empty, Popconfirm, Dropdown, Menu, Switch, Icon, Button } from 'antd';
import { ScheduleType } from '@shared/types/backend.types';
import React, { useState, useCallback } from 'react';
import EditScheduleModal from './edit.schedule';
import LoadingIndicator from '@shared/floating-button/LoadingIndicator';
import AddScheduleModal from './add.schedule';
import ContactUsButton from '@shared/floating-button/ContactUsButton';

// TYPESCRIPT TYPES
interface IScheduleTableProps {
  schedules: any[];
  isLoading: boolean;
  handleEdit: (scheduleJobId: string, jobDetails: any) => Promise<void>;
  handleRemove: (scheduleJobId: string) => Promise<void>;
  handleToggleStatus: (
    jobDetails: any,
    scheduleJobId: any,
    currentStatus: boolean,
  ) => Promise<void>;
  handleSend: (jobDetails: any) => Promise<void>;
  user: any;
  fetchSchedules?: () => Promise<void>;
  fetchAssociates: any;
}

const ScheduleTableComponent: React.FC<IScheduleTableProps> = ({
  schedules,
  isLoading,
  handleRemove,
  handleEdit,
  handleToggleStatus,
  handleSend,
  user,
  fetchSchedules,
  fetchAssociates,
}) => {
  const columns = [
    {
      title: <b>Scheduled Report Title</b>,
      dataIndex: 'jobTitle',
      key: 'jobTitle',
      render: (text: string) => <b>{text}</b>,
    },
    {
      title: <b>Type</b>,
      dataIndex: 'activityType',
      key: 'activityType',
      render: (activityType: string) => {
        if (activityType === 'submissions_report') {
          return 'Submission Report';
        } else if (activityType === 'ca_report') {
          return 'Corrective Action Report';
        } else if (activityType === 'ptp_submissions_report') {
          return 'Pre Task Plan Report';
        } else if (activityType === 'cc_report') {
          return 'Contractor Companies Report';
        } else if (activityType === 'projects_report') {
          return 'Projects Report';
        } else if (activityType === 'associates_report') {
          return 'Associates Report';
        }
        else if (activityType === 'goformz_report') {
          return 'Goformz Report';
        }
        return activityType || <b className="sa-clickable">N/A</b>;
      },
    },
    {
      title: <b>Frequency</b>,
      dataIndex: 'jobFrequency',
      key: 'jobFrequency',
      render: (text: string, record: any) => {
        const getOrdinalSuffix = (n: number): string => {
          const j = n % 10;
          const k = n % 100;
          if (j === 1 && k !== 11) return 'st';
          if (j === 2 && k !== 12) return 'nd';
          if (j === 3 && k !== 13) return 'rd';
          return 'th';
        };

        // Capitalize the first letter of the string
        const capitalizeFirstLetter = (str: string): string => {
          return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
        };

        const formattedText = text.replace(/\b\w/g, char => char.toUpperCase());
        const jobFrequencyValue = capitalizeFirstLetter(record.jobFrequencyValue);

        if (text.toLowerCase() === 'weekly') {
          return `Weekly (Every ${jobFrequencyValue})`;
        }
        if (text.toLowerCase() === 'monthly') {
          // Remove leading zero
          const rawValue = record.jobFrequencyValue;
          const date = parseInt(rawValue, 10); // Convert to number to remove leading zero
          const ordinalSuffix = getOrdinalSuffix(date);
          return `Monthly (${date}${ordinalSuffix} of Every Month)`;
        }
        return formattedText;
      },
    },
    {
      title: <b>Time</b>,
      dataIndex: 'jobTime',
      key: 'jobTime',
      render: (time: string) => time || <b className="sa-clickable">N/A</b>,
    },
    {
      title: <b>Recipient Emails</b>,
      dataIndex: 'recipientEmails',
      key: 'recipientEmails',
      render: (recipientEmails: string) => recipientEmails || <b className="sa-clickable">N/A</b>,
    },
    {
      title: <b>Status</b>,
      dataIndex: 'status',
      key: 'status',
      render: (text: any) => {
        const formattedText = text.charAt(0).toUpperCase() + text.slice(1);
        const color = text.toLowerCase() === 'active' ? 'green' : 'red';
        return <span style={{ color }}>{formattedText}</span>;
      },
    },

    {
      title: <b>Actions</b>,
      key: 'actions',
      render: (_: any, record: any) => (
        <Dropdown
          trigger={['click']}
          overlay={
            <Menu>
              <Menu.Item>
                <a onClick={() => handleSend(record)}>
                  <Icon type="export" />
                  <b style={{ margin: '0px 10px', fontSize: '1.0rem' }}>&nbsp;Export Report</b>
                </a>
              </Menu.Item>
              <Menu.Item>
                <a onClick={() => handleEditClick(record)}>
                  <Icon type="edit" />
                  <b style={{ margin: '0px 10px', fontSize: '1.0rem' }}>&nbsp;Edit Report</b>
                </a>
              </Menu.Item>
              <Menu.Item>
                <div
                  style={{ display: 'flex', alignItems: 'center' }}
                  onClick={e => e.stopPropagation()} // Prevent event propagation
                >
                  <Icon type="user-delete" />
                  <b style={{ margin: '0px 10px', fontSize: '1.0rem' }}>
                    {record.status.toLowerCase() === 'active' ? 'Active' : 'Inactive'}
                  </b>
                  <Switch
                    checked={record.status.toLowerCase() === 'active'}
                    onChange={checked => handleToggleStatus(record, record.scheduleJobId, checked)}
                  />
                </div>
              </Menu.Item>
              <Menu.Item>
                <Popconfirm
                  title="Are you sure you want to delete this report?"
                  onConfirm={() => handleRemove(record.scheduleJobId)}
                  okText="Yes"
                  cancelText="No"
                >
                  <div className="team-actions-dropdown-content">
                    <Icon type="delete" />
                    <b style={{ margin: '0px 10px', fontSize: '1.0rem' }}>Delete Report&nbsp;</b>
                  </div>
                </Popconfirm>
              </Menu.Item>
            </Menu>
          }
        >
          <span className="ant-dropdown-link" onClick={e => e.preventDefault()}>
            <Icon type="more" />
          </span>
        </Dropdown>
      ),
    },
  ];

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedSchedule, setSelectedSchedule] = useState<any | null>(null);
  const [isAddModalVisible, setIsAddModalVisible] = useState(false);
  const handleEditClick = (schedule: ScheduleType) => {
    setSelectedSchedule(schedule);
    setIsModalVisible(true);
  };

  const handleUpdateSchedule = async (jobDetails: any) => {
    if (selectedSchedule) {
      await handleEdit(selectedSchedule.scheduleJobId, jobDetails);
      setIsModalVisible(false);
      setSelectedSchedule(null);
    }
  };

  const handleCreateSchedule = async (newJobDetails: any) => {
    console.log('Creating new schedule:', newJobDetails);
    setIsAddModalVisible(false);
  };

  const reloadSchedules = useCallback(async () => {
    if (fetchSchedules) {
      setIsAddModalVisible(false);
      await fetchSchedules();
    }
  }, [fetchSchedules]);



  const getRecipientEmails = () => {
    const emailSet = new Set<string>();

    schedules.forEach(job => {
      const emails = job.recipientEmails
        .split(',')
        .map((email: string) => email.trim())
        .filter((email: string) => email);

      emails.forEach((email: string) => emailSet.add(email));
    });

    fetchAssociates.forEach((email: any) => emailSet.add(email));

    return Array.from(emailSet);
  };


  console.log('getRecipientEmails',getRecipientEmails());

  return (
    <div className="sa__container">
      {!isAddModalVisible ? (
        <div className="sa__box">
          <div className="sa__box-heading">
            <h4 className="sa__box-heading-title">Scheduled Reports List</h4>
            <Button
              type="primary"
              style={{ marginLeft: 'auto' }}
              onClick={() => setIsAddModalVisible(true)}
            >
              Create New Report
            </Button>
          </div>

          <div className="sa__box-content">
            {isLoading ? (
              <div>
                <LoadingIndicator />
              </div>
            ) : schedules.length ? (
              <Table
                columns={columns}
                dataSource={schedules}
                pagination={false}
                rowKey="scheduleJobId"
                bordered
              />
            ) : (
              <Empty description="No Schedule Jobs Found" />
            )}
          </div>
        </div>
      ) : (
        <ContactUsButton
          openModal={isAddModalVisible}
          onClose={reloadSchedules}
          emailLists={getRecipientEmails()}
          user={user}
          onSubmit={reloadSchedules}
        />
      )}

      <EditScheduleModal
        visible={isModalVisible}
        schedule={selectedSchedule}
        onClose={() => setIsModalVisible(false)}
        onUpdate={handleUpdateSchedule}
        emailLists={getRecipientEmails()}
      />
    </div>
  );
};

export default ScheduleTableComponent;
