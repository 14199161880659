//TODO: add type
export const spanishDictionary: any = {
  sidemenu: {
    avatar: {
      hello: 'Hola',
    },
    systemAdmin: {
      menuTitle: 'Menu',
      dashboard: 'Dashboard',
      invite: 'Invitaciones',
      safetyPrograms: 'Programas de Seguridad',
      reports: 'Reportes',
      account: 'Cuentas',
      submissions: 'Formularios',
      subMenu: {
        inviteTeam: 'Invitar Usuarios',
        inviteAdministrator: 'Invitar Administrator',
      },
    },
    safetyProfessional: {
      safetyPrograms: 'Programas de seguridad',
      reports: 'Reportes',
      projects: 'Proyectos',
      account: 'Cuenta',
      submissions: 'Formularios',
      scheduleJobs: 'Informes programados',
      goformz: 'Goformz'
    },
    dashboard: {
      sectionTitle: 'Panel',
      customization: 'Personalización de Panel',
      reports: 'Reportes',
    },
    program: {
      sectionTitle: 'Programa',
      appBuilder: 'Creador de Aplicaciones',
      broadcast: 'Transmisiones',
      templates: 'Plantillas',
      workflow: 'Flujo de Trabajo',
    },
    teamManagement: {
      sectionTitle: 'Gestión de Personal',
      users: 'Usuarios',
      groups: 'Grupos',
    },
    more: {
      sectionTitle: 'Más Información',
      help: 'Ayuda',
    },
  },
  wizard: {
    titles: {
      companyProfile: '¡Configuremos el set up de tu compañía!',
      safetyPrograms: '¡Agreguemos Programas de Seguridad!',
      project: '¡Creemos algunos proyectos!',
      jobTitles: 'Creemos algunos titulos de trabajo!',
      team: '¡Agreguemos a tu equipo!',
    },
    headings: {
      profile: 'Perfil',
      safetyProgram: 'Programa de Seguridad',
      team: 'Equipo',
      finish: 'Concluir',
    },
    profile: {
      companyLogo: 'Logo de la compañía',
    },
    safetyProgram: {
      forms: 'Formularios',
      question: 'Preguntas',
    },
    team: {
      messageClick: 'Haz click',
      messageCSV:
        'y descarga tu archivo CSV. Agrega a los miembros de tu equipo e importa la información utilizando nuestra herramienta de importación.',
      upload: 'Arrastra para subir tu hoja de cálculo o tu archivo .CSV',
    },
    finish: {
      sectionTitle: '¡Felicidades!',
      message: 'Tu perfil de compañía, proyectos, y equipo, hanssido exitosamente creados.',
    },
    projects: {
      listNumber: 'Número de proyecto',
      listName: 'Nombre del proyecto',
      listAddress: 'Dirección del proyecto',
    },
  },
  registration: {
    titleHello:
      'Hola, Velocity Vue te invita a activar tu cuenta para la aplicación de Safety Assist',
    message: 'Por favor llena el formulario para activar tu cuenta.',
    formTitle: 'Tu información',
    emergencyTitle: 'Información de Contacto para emergencias',
    createYourAccount: 'Crea tu cuenta',
  },
  login: {
    restorePasswordTitle: 'Restablecer contraseña',
    restorePasswordMessage: 'Escribe tu nueva contraseña',
  },
  buttons: {
    browse: 'Buscar',
    previous: 'Anterior',
    continue: 'Continuar',
    cancel: 'Cancelar',
    done: 'Finalizar',
    submit: 'Finalizar',
    emailMe: 'Envíar link por email',
    restorePassword: 'Restablecer contraseña',
    ok: 'Ok',
    register: 'Registrar',
    logout: 'Logout',
    login: 'Iniciar Sesión',
    loginManually: 'Ingresar Manualmente',
    invite: 'Invitar',
    back: 'Atrás',
    next: 'Siguiente',
    here: 'here',
  },
  inputs: {
    add: 'Agregar',
    name: 'Nombre(s)',
    firstName: 'Nombre(s)',
    lastName: 'Apellidos',
    email: 'Correo electrónico',
    emailAddress: 'Correo electrónico',
    address: 'Dirección',
    contactNumber: 'Teléfono',
    dayOfBirth: 'Día de Nacimiento',
    optional: 'Optional',
    contactName: 'Nombre de Contacto',
    contactPhone: 'Teléfono de Contacto',
    contactEmail: 'Correo eléctronico de contacto',
    password: 'Contraseña',
    confirmPassword: 'Confirmar Contraseña',
    company: 'Compañía',
    companyName: 'Nombre de la compañía',
    companyAddress: 'Dirección de la compañía',
    role: 'Rol',
    rememberPassword: 'Recordar contraseña',
    forgotPassword: '¿Olvidaste tu contraseña?',
    wrongPassword: 'El correo o contraseña ingresada son incorrectos.',
    industry: 'Industria',
    state: 'Estado',
    jobTitle: 'Puesto de trabajo',
    reportsTo: 'Reporta a',
    createProject: 'Crea proyectos',
    requiredName: 'Por favor, ingrese nombre(s)',
    requiredFirstName: 'Por favor, ingrese nombre(s)',
    requiredLastName: 'Por favor, ingrese apellidos',
    requiredEmail: 'Por favor, ingrese un correo electrónico válido',
    requiredEmailAddress: 'Por favor, ingrese un correo electrónico válido',
    requiredAddress: 'Por favor, ingrese dirección',
    requiredContactNumber: 'Por favor, ingrese número de contacto',
    requiredContactName: 'Por favor, ingrese nombre de contacto',
    requiredContactPhone: 'Por favor, ingrese teléfono de contacto',
    requiredPassword: 'Por favor, ingrese contraseña',
    requiredConfirmPassword: 'Por favor, ingrese confirmación de contraseña',
    requiredCompany: 'Por favor, ingrese compañía',
    requiredCompanyAddress: 'Por favor, ingrese la dirección de la compañía',
    requiredRole: 'Por favor, ingrese role',
    requiredIndustry: 'Por favor, seleccione una industria',
    requiredState: 'Por favor, seleccione un estado',
    requiredJobTitle: 'Por favor, seleccione un puesto de trabajo',
    invalidImageGreater: 'El tamaño de la imagen debe ser menor a',
    invalidImageLower: 'El tamaño de la imagen debe ser mayor a',
    invalidImageSize: 'La imagen no debe pesar más de',
    invalidImage: 'La imagen no debe ser mayor a 2MB ni 720px x 1280px',
    weakPassword: 'La contraseña es muy débil.',
    invalidPassword:
      'La contraseña debe contener al menos 6 digitos, una letra mayúscula, un número y un caracter especial',
    unmatchingPassword: 'Las contraseñas ingresadas no coinciden.',
    requiredNoLicences: "Please enter No. of Licences",
    requiredLicenceType: "Please select Licence Type",
    requiredReportsTo: "Please select Reporta a",
    contractorCompany: 'Contractor Compañía'
  },
  months: {
    0: 'Enero',
    1: 'Febrero',
    2: 'Marzo',
    3: 'Abril',
    4: 'Mayo',
    5: 'Junio',
    6: 'Julio',
    7: 'Agosto',
    8: 'Septiembre',
    9: 'Octubre',
    10: 'Noviembre',
    11: 'Diciembre',
  },
  signin: {
    welcome: 'Iniciar sesión',
    instruction: 'Por favor ingresa a tu cuenta.',
  },
  password: {
    pleaseCreate: 'Por favor, crea una contraseña para tu cuenta',
  },
  help: {
    phone: 'No te preocupes, si nos necesitas sólo llama al 1-800-XXX-XXX',
    notFoundTitle: 'Oops! Parece que el sitio que buscas no existe.',
    notFoundSubtitle: 'Revisa la dirección web e intenta de nuevo.',
    contactSupport: 'Contactar a Soporte',
  },
  congratulations: {
    congratulations: 'Congratulations!',
    accountActivated: 'Your account has been successfully activated and password created!',
    ableToLogin: 'You are able to login into your',
    setCompanyProfile: 'and set your company profile.',
    title: '¡Felicidades! Estás listo para continuar',
    emailReceivePrefix: 'Recibirás un email en',
    emailReceiveSuffix: 'con un link para crear tu contraseña',
    yourInbox: 'tu bandeja',
    dashboardPreMessage: 'Ahora puedes ir a ty',
    dashboardPostMessage: 'y crear el perfil de tu compañía.',
    orMessage: 'O',
    mobileMessage: 'tu aplicación para celular.',
    application: 'aplicación',
    download: 'Descargar',
    dashboard: 'dashboard',
    loginToDashboard: 'Acceder al dashboard',
    thanksSignUp: 'Thanks for Signing Up',
  },
  modal: {
    error: {
      ups: 'Ups!',
    },
    add: {
      supervisors: {
        title: 'Agregar nuevo supervisor',
        successTitle: '¡Éxito!',
        successMessage: 'El supervisor fue agregado exitosamente.',
      },
      company: {
        title: 'Crear nueva compañía',
        successTitle: '¡Éxito!',
        successMessage: 'Tu compañía fue agregada exitosamente.',
        errorMessage: 'Ha habido un error al crear tu compañía.',
      },
    },
    invite: {
      title: '¡Invitación enviada!',
      message: 'La invitaciòn fue enviada exitosamente a',
      error: 'Ha habido un error al enviar la invitación',
    },
    register: {
      title: '¡Felicidades!',
      message: 'Tu cuenta ha sido activada exitosamente y tu contraseña ha sido creada.',
      error: 'Ha habido un error en el registro.',
    },
    restorePassword: {
      title: 'Email Sent!',
      message: 'You will receive an email with a link to restore your password.',
      error: 'There has been an error when sending restore email.',
    },
    passwordRestored: {
      title: 'Contraseña actualizada!',
      message: 'Puedes usar tus nuevas credenciales ahora.',
      error: 'Ha habido un error al actualizar la contraseña.',
    },
    setupCompany: {
      message: 'Has creado tu cuenta, ahora crearemos tu perfil de compañía!',
    },
  },
  dashboard: {
    admin: {
      invite: {
        formTitle: 'Invitación Administrador',
        formMessage: 'Crea un perfil para Safety Professional o Administrador.',
      },
    },
  },
};
